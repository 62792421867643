import moment from 'moment';

export function isUnderAge(value, years, format) {
    try {
        const minimum = moment(value, format || 'YYYY-MM-DD').add({
            years
        });

        return minimum.isBefore(moment());
    } catch (e) {
        return false;
    }
}
