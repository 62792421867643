import Vue from 'vue';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

import {
    PLATFORMS,
    POS_PLATFORMS,
    PLATFORM_INDEX,
    DEFAULT_PLATFORM,
    MOBILE_PLATFORMS,
    IOS_PLATFORM,
    ANDROID_PLATFORM,
    POS_IOS_PLATFORM
} from '@/constants/platforms';
import Browser from '@/mixins/browser';

@Component
export default class Platform extends mixins(Browser) {
    get platform() {
        let platform = Vue.$sessionStorage.getItem('platform');

        if (this.$route.query.platform) {
            platform = this.$route.query.platform;
        }

        return platform && this.isValidPlatform(platform)
            ? platform
            : DEFAULT_PLATFORM;
    }

    get platformIndex() {
        return PLATFORM_INDEX[this.platform] || PLATFORM_INDEX[DEFAULT_PLATFORM];
    }

    setPlatform(platform?: string | null) {
        if (!platform) {
            platform = this.$route.query.platform;
        }

        if (this.isValidPlatform(platform)) {
            Vue.$sessionStorage.setItem('platform', platform);
        }
    }

    isValidPlatform(platform: string) {
        return PLATFORMS.indexOf(platform) > -1;
    }

    get isMobilePlatform() {
        return MOBILE_PLATFORMS.indexOf(this.platform) > -1;
    }

    get isPosPlatform() {
        return POS_PLATFORMS.indexOf(this.platform) > -1;
    }

    get isWebView() {
        return this.isMobileBrowser || this.isMobilePlatform || this.isPosPlatform;
    }

    get isIosPlatform() {
        return this.platform === IOS_PLATFORM;
    }

    get isPosIosPlatform() {
        return this.platform === POS_IOS_PLATFORM;
    }

    get isAndroidPlatform() {
        return this.platform === ANDROID_PLATFORM;
    }
}
