import { Model } from '@vuex-orm/core';

import { AssociatedPersonAddress, AddressOptions } from '@/store/models';

export class AssociatedPerson extends Model {
    static entity = 'associatedPerson';

    static fields() {
        return {
            id: this.attr(null),
            email: this.attr(null),
            firstName: this.attr(null),
            lastName: this.attr(null),
            fathersName: this.attr(null),
            mothersName: this.attr(null),
            dateOfBirth: this.attr(null),
            birthCountryCode: this.attr(null),
            birthCity: this.attr(null),
            mobile: this.attr(null),
            shareholdingPercentage: this.number(0),
            ultimateShareholdingPercentage: this.number(0),
            nationalityCountryCodes: this.attr(null),
            taxLiabilities: this.attr(null),
            occupation: this.attr(null),
            isAuthorizedAdmin: this.boolean(false),
            isLegalRepresentative: this.boolean(false),
            politicallyExposedPerson: this.boolean(false),
            controlType: this.attr(null),
            controlTypeOther: this.attr(null),
            missingTinReason: this.attr(null),
            missingTinReasonOther: this.attr(null),
            citizenshipCountryCodes: this.attr(null),
            uboChangeDeclaration: this.boolean(null),
            personalDataDeclaration: this.boolean(null),
            new: this.boolean(false),
            modelId: this.attr(null),
            modelType: this.attr(null),
            address: this.morphOne(AssociatedPersonAddress, 'modelId', 'modelType'),
            additionalAddresses: this.attr(null),
            roles: this.attr(null),
            usCitizen: this.attr(null),
            usTaxIdentity: this.attr(null)
        };
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
}

export class AssociatedPersonOptions {
    lastName: string;
    email: string;
    firstName: string;
    mobile: string;
    address: AddressOptions;
    dateOfBirth: Date;
    birthCountryCode: string;
    shareholdingPercentage?: number;
    roles: Array<number>;
    usCitizen: boolean;
    usTaxIdentity: string;

    constructor(person: AssociatedPerson) {
        this.lastName = person.lastName;
        this.email = person.email;
        this.firstName = person.firstName;
        this.mobile = person.mobile;
        this.dateOfBirth = person.dateOfBirth;
        this.birthCountryCode = person.birthCountryCode;

        if (person.shareholdingPercentage > 0) {
            this.shareholdingPercentage = person.shareholdingPercentage;
        }

        this.roles = person.roles
            ? person.roles.filter((role) => role >= 0)
            : [];
        this.usCitizen = person.usCitizen;
        this.usTaxIdentity = person.usTaxIdentity;

        if (person.address) {
            this.address = new AddressOptions(person.address);
        }
    }
}
