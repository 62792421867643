import { datadogRum } from '@datadog/browser-rum';
import { LoggerOptions } from '@/core/logger/interfaces';

export async function initializeDatadog() {
    if (datadogRum.getInternalContext()) {
        return;
    }

    datadogRum.init({
        applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID as string,
        clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN as string,
        site: process.env.VUE_APP_DATADOG_SITE,
        service: process.env.VUE_APP_DATADOG_SERVICE,
        env: process.env.NODE_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask'
    });
}

export function setDatadogGlobalContext(options: LoggerOptions) {
    datadogRum.setGlobalContext({
        message: options.message,
        clientData: JSON.stringify(options.clientData),
        correlationId: options.correlationId,
        eventId: options.eventId,
        useLogApi: options.useLogApi
    });
}
