import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import { NavigationStateOptions as StateOptions } from '@/constants';
import { State } from '@/store/database';

const persistStateOptions = {
    key: StateOptions.vuex,
    reducer: (state: State) => {
        Vue.$localStorage.setItem(`${StateOptions.vuex}-creation`, `${new Date()}`);

        return {
            entities: {
                navigation: state.entities.navigation
            }
        };
    }
};

export function createPersist() {
    return {
        plugin: createPersistedState(persistStateOptions)
    };
}
