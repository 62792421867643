import Vue from 'vue';

export default {

    getAttributeName(options: FormAttribute.Options, name?: string) {
        const prefix = options.prefix || 'data-';

        return name ? prefix + name : options.id;
    },

    formatAttributeValue(value: string) {
        return value.replace(/([a-z])([A-Z])/g, '$1-$2')
            .replace(/\./g, '-')
            .toLowerCase();
    },

    getModelName(data) {
        if (data && data.model && data.model.expression) {
            return this.formatAttributeValue(data.model.expression);
        }

        return '';
    },

    create(options?: FormAttribute.Options) {
        Vue.directive('form-attribute', {
            bind: (el, binding, vnode) => {
                const modelName = this.getModelName(vnode.data);
                const { value } = binding;

                if (!options) {
                    options = {
                        id: 'id'
                    };
                }

                let attributeValue = modelName;
                const attributeName = this.getAttributeName(options, binding.arg);

                if (typeof value === 'string' && value.trim() !== '') {
                    attributeValue = this.formatAttributeValue(value);
                }

                el.setAttribute(attributeName, attributeValue);
            }
        });
    }
};
