import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import { State } from "../database";
import { Culture } from '../models';

const persistState = createPersistedState({
    key: 'culture',
    reducer: (state: State) => {
        Vue.$localStorage.setItem('culture-creation', '' + new Date());

        return {
            entities: {
                [Culture.entity]: state.entities.culture
            }
        }
    }
});

export default persistState;
