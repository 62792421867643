import bundle from '@/utilities/bundle';

import { join } from '@/utilities/url';
import { INVITATION_ROOT_PATH } from './base';

export const INVITATION_CLIENT_START_ROUTE = {
    path: '/',
    name: 'invite-client-start',
    component: bundle.loadView('invitation/client/start/start'),
    props: true
};

export const INVITATION_CLIENT_PERSONAL_DETAILS_ROUTE = {
    path: '/details',
    name: 'invite-client-details',
    component: bundle.loadView('invitation/client/personal-details/personal-details'),
    props: true
};

export const INVITATION_CLIENT_AUTH_ROUTE = {
    path: '/auth',
    name: 'invite-client-auth',
    component: bundle.loadView('invitation/client/auth/auth'),
    props: true
};

export const INVITATION_CLIENT_SUCCESS_ROUTE = {
    path: '/success',
    name: 'invite-client-success',
    component: bundle.loadView('invitation/client/success/success'),
    props: true
};

export const INVITATION_CLIENT_PROCESS_ROUTE = {
    path: '/process',
    name: 'invite-client-process',
    component: bundle.loadView('invitation/client/process/process'),
    props: true
};

export const INVITATION_CLIENT_ROUTES = {
    path: join(INVITATION_ROOT_PATH, 'client'),
    children: [
        INVITATION_CLIENT_START_ROUTE,
        // INVITATION_CLIENT_PERSONAL_DETAILS_ROUTE,
        // INVITATION_CLIENT_AUTH_ROUTE,
        // INVITATION_CLIENT_SUCCESS_ROUTE,
        INVITATION_CLIENT_PROCESS_ROUTE
    ]
};
