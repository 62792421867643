import axios from 'axios';

import { join } from '@/utilities/url';
import { SERVICES_BASE_URL } from '@/constants/url';

const VivaPaymentsAuth = axios.create({
    baseURL: join(SERVICES_BASE_URL, 'auth')
});

export default VivaPaymentsAuth;
