import bundle from '@/utilities/bundle';

export const ASSOCIATE_HOME_ROUTE = {
    path: '/',
    name: 'associations',
    component: bundle.loadView('business/associations/associations'),
    props: true,
    meta: {
        title: 'merchant.associatedPeople.title'
    }
};

export const ASSOCIATE_ADD_ROUTE = {
    path: '/add',
    name: 'add-association',
    component: bundle.loadView('business/associations/person/person'),
    props: true,
    meta: {
        title: 'headline.addAssociatedPeople'
    }
};

export const ASSOCIATE_EDIT_ROUTE = {
    path: '/edit/:associateId',
    name: 'edit-association',
    component: bundle.loadView('business/associations/person/person'),
    props: true,
    meta: {
        title: 'headline.addAssociatedPeople'
    }
};

export const ASSOCIATE_SUGGEST_ROUTE = {
    path: '/suggest/:associateId',
    name: 'suggest-association',
    component: bundle.loadView('business/associations/person/person'),
    props: true,
    meta: {
        title: 'headline.addAssociatedPeople'
    }
};

export const ASSOCIATE_ROUTES = {
    path: '/business/associated',
    children: [
        ASSOCIATE_HOME_ROUTE,
        ASSOCIATE_ADD_ROUTE,
        ASSOCIATE_EDIT_ROUTE,
        ASSOCIATE_SUGGEST_ROUTE
    ]
};
