import { Model } from '@vuex-orm/core';

export class ClientCustomerData extends Model {
    static entity = 'clientCustomerData';

    static fields() {
        return {
            id: this.attr(null),
            data: this.attr(null)
        };
    }
}
