import { Model } from '@vuex-orm/core';

export class InvitationLabCbd extends Model {
    static entity = 'invitationLabCbd';

    static fields() {
        return {
            id: this.attr(null),
            countryCode: this.attr(''),
            hasHemp: this.boolean(false),
            hasMarijuana: this.boolean(false),
            consent: this.boolean(false),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }

    get hasProducts() {
        return this.hasHemp || this.hasMarijuana;
    }
}
