import bundle from '@/utilities/bundle';

// COMMON
export const ACCOUNT_MANAGEMENT_START_ROUTE = {
    path: '/',
    name: 'account-management-start',
    component: bundle.loadView('account-management/start/start'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_PROCESS_ROUTE = {
    path: '/process',
    name: 'account-management-process',
    component: bundle.loadView('account-management/process/process'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_SELECT_ACCOUNT_ROUTE = {
    path: '/select-account',
    name: 'account-management-select-account',
    component: bundle.loadView('account-management/select-account/select-account'),
    props: true,
    meta: {
        title: 'accountManagement.start.title'
    }
};

export const ACCOUNT_MANAGEMENT_SELECT_SCOPE_ROUTE = {
    path: '/select-scope/:id',
    name: 'account-management-select-scope',
    component: bundle.loadView('account-management/select-scope/select-scope'),
    props: true,
    meta: {
        title: 'accountManagement.start.title'
    }
};

export const ACCOUNT_MANAGEMENT_BASE_ROUTES = {
    path: '/account',
    children: [
        ACCOUNT_MANAGEMENT_START_ROUTE,
        ACCOUNT_MANAGEMENT_PROCESS_ROUTE,
        ACCOUNT_MANAGEMENT_SELECT_SCOPE_ROUTE,
        ACCOUNT_MANAGEMENT_SELECT_ACCOUNT_ROUTE
    ]
};
