import Vue from 'vue';

import {
    POS_CODE_ACTIVATION_STORAGE,
    POS_CODE_ACTIVATION_FAIL_URI,
    POS_CODE_ACTIVATION_SUCCESS_URI,
    POS_CODE_ACTIVATION_URI_STORAGE
} from '@/constants/pos';
import pos from '@/services/core/pos/pos';
import { getUrlParameterByName } from '@/utilities/url';
import { logger } from '@/core/logger';

export function getActivationPosCodeFromUrl() {
    return getUrlParameterByName('pos_activation_code')?.trim() ||
        getUrlParameterByName('activationPosCode')?.trim();
}

export async function activateDevice(posPlatform: boolean = true) {
    const posCode = Vue.$sessionStorage.getItem(POS_CODE_ACTIVATION_STORAGE);

    if (!posCode) {
        if (posPlatform) {
            Vue.$sessionStorage.setItem(POS_CODE_ACTIVATION_URI_STORAGE, POS_CODE_ACTIVATION_FAIL_URI);
        }

        return;
    }

    const response = await pos.activateDevice({
        code: posCode
    });

    if (!response.success) {
        logger.log({
            message: 'Pos activation failed',
            clientData: {
                code: posCode
            },
            correlationId: response.correlationId,
            response
        });
    }

    if (posPlatform) {
        Vue.$sessionStorage.setItem(POS_CODE_ACTIVATION_URI_STORAGE, response.success
            ? POS_CODE_ACTIVATION_SUCCESS_URI
            : POS_CODE_ACTIVATION_FAIL_URI);
    }
}

export function storeActivationPosCode() {
    const code = getActivationPosCodeFromUrl();

    if (code) {
        Vue.$sessionStorage.setItem(POS_CODE_ACTIVATION_STORAGE, code);
    }
}

export function getActivationDeviceDeepLink() {
    if (!process.env.VUE_APP_DEEPLINK_HOST) {
        return null;
    }

    const uri = Vue.$sessionStorage.getItem(POS_CODE_ACTIVATION_URI_STORAGE);

    if (uri) {
        return `${process.env.VUE_APP_DEEPLINK_HOST.replace(/\/$/, '')}${uri}`;
    }

    return null;
}
