import moment from 'moment';

export function isOverAge(value, years, format) {
    try {
        const maximum = moment(value, format || 'YYYY-MM-DD').add({
            years
        });

        return maximum.isAfter(moment());
    } catch (e) {
        return false;
    }
}
