import { RouteConfig } from 'vue-router';

export const REDIRECT_ROUTE: RouteConfig = {
    path: '/redirect',
    name: 'redirect',
    component: () => import('@/views/redirect/redirect.vue')
};

export const VIVA_COM_LEAD_FORM_REDIRECT_ROUTE: RouteConfig = {
    ...REDIRECT_ROUTE
};
