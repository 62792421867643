import Vue from "vue";
import moment, { MomentInputObject } from 'moment'

import FingerPrintStorage from "./fingerprint-storage";
import { Transform } from "../../utilities";

export default class FingerPrintLocalStorage implements FingerPrintStorage {
    constructor(readonly expires: MomentInputObject, readonly transform: Transform) {}

    isExpired(creation: string) {
        const ttl = moment(creation).add(this.expires);

        return ttl.isBefore(moment());
    }

    checkExpiration(key: string) {
        let time = this.getCreation(key);

        for (let storageKey in time) {
            let storageKeyCreation = time[storageKey];

            if (this.isExpired(storageKeyCreation)) {
                Vue.$localStorage.removeItem(storageKey);
                delete time[storageKey];
            }
        }

        Vue.$localStorage.setItem(key, JSON.stringify(time));
    }

    getCreation(key: string) {
        return JSON.parse(Vue.$localStorage.getItem(key) || '{}');
    }

    setCreation(key: string, storageKey: string) {
        let time = this.getCreation(key);

        time[storageKey] = new Date();

        Vue.$localStorage.setItem(key, JSON.stringify(time));
    }

    getStorageKey(key: string, id :string) {
        return `${key}-${this.transform.hash(id)}`;
    }

    getItem(key) {
        const fingerPrint = Vue.$fingerPrint.get();

        // Ignore key equals @@
        if (fingerPrint && key !== '@@') {
            try {
                const storageKey = this.getStorageKey(key, fingerPrint.id);
                const state = Vue.$localStorage.getItem(storageKey);

                return state ? this.transform.decrypt(fingerPrint.id, state) : null;
            } catch (e) {}
        }

        return null;
    }

    setItem(key, value) {
        const fingerPrint = Vue.$fingerPrint.get();

        // Ignore key equals @@
        if (fingerPrint && key !== '@@') {
            try {
                const storageKey = this.getStorageKey(key, fingerPrint.id);
                const encrypt = this.transform.encrypt(fingerPrint.id, value);

                if (encrypt) {
                    Vue.$localStorage.setItem(storageKey, encrypt);

                    this.setCreation(key, storageKey);
                }
            } catch (e) {}
        }
    }

    removeItem(key)  {
        const fingerPrint = Vue.$fingerPrint.get();

        if (fingerPrint) {
            const storageKey = this.getStorageKey(key, fingerPrint.id);

            Vue.$localStorage.removeItem(storageKey);
        }
    }
}
