import { VIVA_COM_LEAD_FORM_REDIRECT_ROUTE } from './redirect';

export const CLIENT_LOCATION_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/',
    name: 'location'

};

export const CLIENT_BUSINESS_CREATE_CONFIRMATION_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/business-creation',
    name: 'business-creation'
};

export const CLIENT_EMAIL_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/email',
    name: 'email'
};

export const CLIENT_PERSONAL_DETAILS_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/personal-details',
    name: 'personal-details'
};

export const CLIENT_BIRTH_DETAILS_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/birth-details',
    name: 'birth-details'
};

export const CLIENT_US_CITIZENSHIP_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/us-citizenship',
    name: 'us-citizenship'
};

export const CLIENT_TAX_INFORMATION_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/tax-information',
    name: 'tax-information'
};

export const CLIENT_FINANCIAL_PROFILE_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/financial-profile',
    name: 'financial-profile'
};

export const CLIENT_SOURCE_OF_FUNDS_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/source-of-funds',
    name: 'source-of-funds'
};

export const CLIENT_OCCUPATION_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/occupation',
    name: 'occupation'
};

export const CLIENT_PERSONAL_ADDRESS_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/personal-address',
    name: 'personal-address'
};

export const CLIENT_PASSWORD_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/password',
    name: 'password'
};

export const CLIENT_ROUTES = [
    CLIENT_LOCATION_ROUTE,
    CLIENT_BUSINESS_CREATE_CONFIRMATION_ROUTE,
    CLIENT_EMAIL_ROUTE,
    CLIENT_PERSONAL_DETAILS_ROUTE,
    CLIENT_BIRTH_DETAILS_ROUTE,
    CLIENT_US_CITIZENSHIP_ROUTE,
    CLIENT_TAX_INFORMATION_ROUTE,
    CLIENT_FINANCIAL_PROFILE_ROUTE,
    CLIENT_SOURCE_OF_FUNDS_ROUTE,
    CLIENT_OCCUPATION_ROUTE,
    CLIENT_PERSONAL_ADDRESS_ROUTE,
    CLIENT_PASSWORD_ROUTE
];
