import { Options } from "vuex-persistedstate";

import FingerPrintStorage from './storage/fingerprint-storage'

export class FingerPrintPersist {
    constructor(
        readonly key: string,
        readonly storage: FingerPrintStorage,
        options?: any) {

        options = Object.assign({
            key: this.key,
            storage: this.storage
        }, options);

        this.persistedStateOptions = options;

        this.storage.checkExpiration(this.key);
    }

    persistedStateOptions: Options;

    getState() {
        return this.storage.getItem(this.key)
    }

    setState(value) {
        this.storage.setItem(this.key, value);
    }

    removeState() {
        this.storage.removeItem(this.key);
    }
}
