import { Auth, AuthenticationScheme } from './auth';
import { Base64 } from "js-base64";

export class IdentityAppAuth implements Auth {
    credentials: string;

    constructor(
        readonly scheme: AuthenticationScheme,
        readonly secret: string,
        readonly clientId: string) {
        this.credentials = Base64.encode(`${this.clientId}:${this.secret}`);
    }

    async init() {}

    isValid() {
        return this.scheme && this.credentials !== '';
    }
}
