import { extend } from 'vee-validate';

import { isMoreThan } from '@/core/rules';

extend('more_than', {
    validate(value, { actualValue, min }:any) {
        return isMoreThan(value, { actualValue, min });
    },
    params: ['actualValue', 'min']
});
