import { extend } from 'vee-validate';

import { isUnderAge } from '@/core/rules';

extend('under_age', {
    validate(value, { years, format }: any) {
        return isUnderAge(value, years, format);
    },
    params: ['years', 'format']
});
