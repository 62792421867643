import { extend } from 'vee-validate';

import { isIbanValid } from '@/core/rules';

extend('iban', {
    validate(value, { countryCode }: any) {
        return isIbanValid(value, countryCode);
    },
    params: ['countryCode']
});
