import createPersistedState from 'vuex-persistedstate';

import { State } from '@/store/database';
import * as FingerPrint from '@/core/fingerprint';

const StateOptions = {
    vuex: 'vuex-account-management'
};

const persistStateOptions = {
    reducer: (state: State) => ({
        entities: {
            accountCustomerData: state.entities.accountCustomerData,
            clientCustomerData: state.entities.clientCustomerData,
            merchantCustomerData: state.entities.merchantCustomerData
        }
    })
};

export function createPersist() {
    const storage = new FingerPrint.Store.SessionStorage(
        new FingerPrint.Utilities.CryptoTransform()
    );

    const Persist = new FingerPrint.Store.Persist(
        StateOptions.vuex,
        storage,
        persistStateOptions
    );

    return {
        plugin: createPersistedState(Persist.persistedStateOptions),
        persist: Persist
    };
}
