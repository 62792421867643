import { extend } from 'vee-validate';

import { nonUk } from '@/core/rules';

extend('non_uk', {
    validate(value, { countryCode, companyType }:any) {
        return nonUk(value, { countryCode, companyType });
    },
    params: ['countryCode', 'companyType']
});
