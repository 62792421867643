import { extend } from 'vee-validate';

import { isLessThan } from '@/core/rules';

extend('less_than', {
    validate(value, { actualValue, max }:any) {
        return isLessThan(value, { actualValue, max });
    },
    params: ['actualValue', 'max']
});
