import { extend } from 'vee-validate';

import { isMinYear } from '@/core/rules';

extend('min_year', {
    validate(value: any, { year }: any) {
        return isMinYear(value, year);
    },
    params: ['year']
});
