import { Route } from 'vue-router';

import { InvitationType } from '@/constants/invitation-type';
import { INVITATION_START_ROUTE } from '@/routes/invitation/base';

export const AFFILIATE_HOME_ROUTE = {
    path: '/lead/:leadId',
    name: 'lead',
    props: true,
    redirect: (to: Route) => {
        const { params, query } = to;

        return {
            name: INVITATION_START_ROUTE.name,
            params: {
                ...params,
                invitationId: params.leadId,
                invitationType: InvitationType.SalesPortal
            },
            query
        };
    }
};

export const AFFILIATE_ROUTES = {
    path: '/affiliate',
    children: [
        AFFILIATE_HOME_ROUTE
    ]
};
