export function isHexColorValid(hexCode: string) {
    if (!hexCode) {
        return false;
    }

    const hex = hexCode.replace('#', '');

    return /^(?:[0-9a-fA-F]{3}){1,2}$/.test(hex);
}

export function convertHexToRGBA(hexCode: string, opacity: number = 1) {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        opacity /= 100;
    }

    return {
        red,
        green,
        blue,
        opacity
    };
}

export function convertRGBAToHSLA(red: number, green: number, blue: number, alpha: number) {
    // Make r, g, and b fractions of 1
    red /= 255;
    green /= 255;
    blue /= 255;

    // Find greatest and smallest channel values
    const cmin = Math.min(red, green, blue);
    const cmax = Math.max(red, green, blue);
    const delta = cmax - cmin;
    let hue = 0;
    let saturation = 0;
    let lightness = 0;

    // Calculate hue
    if (delta === 0) {
        // No difference
        hue = 0;
    } else if (cmax === red) {
        // Red is max
        hue = ((green - blue) / delta) % 6;
    } else if (cmax === green) {
        // Green is max
        hue = (blue - red) / delta + 2;
    } else {
        // Blue is max
        hue = (red - green) / delta + 4;
    }

    hue = Math.round(hue * 60);

    // Make negative hues positive behind 360°
    if (hue < 0) {
        hue += 360;
    }

    // Calculate lightness
    lightness = (cmax + cmin) / 2;

    // Calculate saturation
    saturation = delta === 0
        ? 0
        : delta / (1 - Math.abs(2 * lightness - 1));

    // Multiply l and s by 100
    saturation = +(saturation * 100).toFixed(1);
    lightness = +(lightness * 100).toFixed(1);

    return `${hue},${Math.round(saturation)},${Math.round(lightness)}, ${alpha}`;
}

export function convertHexToHSLA(color: string) {
    return convertRGBAToHSLA(
        convertHexToRGBA(color).red,
        convertHexToRGBA(color).green,
        convertHexToRGBA(color).blue,
        convertHexToRGBA(color).opacity
    );
}
