import { Model } from '@vuex-orm/core';

import { VuexOrmExtensions } from '@/core/vuex-orm-extensions';

const { trim } = VuexOrmExtensions.Utilities;

export class InvitationContact extends Model {
    static entity = 'invitationContact';

    static fields() {
        return {
            id: this.attr(null),
            fullName: this.attr('', (value) => trim(value)),
            phone: this.attr('', (value) => trim(value)),
            phonePrefix: this.number((value) => trim(value)),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }
}
