import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import { AccountTypeId } from '@/constants/account-type-id';
import Identity from '@/core/authentication/mixins/identity';
import { TokenConnectService } from '@/services/auth';

@Component
export default class Authentication extends mixins(Identity) {
    auth: boolean = true;

    async hasClientRole() {
        const roles = await this.getUserRoles();

        return roles?.indexOf(AccountTypeId[AccountTypeId.Client]) > -1;
    }

    async hasMerchantRole() {
        const roles = await this.getUserRoles();

        return roles?.indexOf(AccountTypeId[AccountTypeId.Merchant]) > -1;
    }

    async getMerchantId() {
        if (!await this.hasMerchantRole()) {
            return null;
        }

        return await this.getUserId();
    }

    async getClientId() {
        if (!await this.hasClientRole()) {
            return null;
        }

        return await this.getUserId();
    }

    async switchAccount(userId: string, silent?: boolean) {
        if (!userId) {
            return;
        }

        const currentUserId = await this.getUserId();

        if (userId.toLowerCase() !== currentUserId?.toLowerCase()) {
            await this.connectToMultipleAccounts(userId);
        }
    }

    async connectToMultipleAccounts(username: string) {
        const response = await TokenConnectService.connectToMultipleAccounts(
            username, await this.getUserAccessToken()
        );

        if (response.success) {
            await this.storeUser(
                response.data.accessToken, response.data.tokenType
            );
        }
    }

    async connectToAccount(username: string, password: string, countryCode: string) {
        const response = await TokenConnectService.requestPasswordToken(username, password, countryCode);

        if (response.success) {
            await this.storeUser(
                response.data.accessToken, response.data.tokenType
            );
        }
    }

    async logoutUser(redirectUri?: string) {
        await this.signoutRedirect(redirectUri);
    }

    @Watch('culture.lang', { deep: true })
    async onChangeLangAuth(value) {
        this.setQueryParams({
            ui_locales: value
        });
    }
}
