import Vue from 'vue';
import i18next from 'i18next';
import 'unorm';

import { LOCALES, localeSettings } from '@/constants';

const languagesWithUppercaseDiacritics: string[] = [
    localeSettings[LOCALES.English].culture,
    localeSettings[LOCALES.Deutsch].culture,
    localeSettings[LOCALES.Polish].culture,
    localeSettings[LOCALES.Croatian].culture
];

export function removeDiacritics(
    value: string = '',
    exclude: string[] = languagesWithUppercaseDiacritics
) {
    return exclude.indexOf(i18next.language) === -1 && value.length > 1
        ? value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        : value;
}

Vue.filter('uppercase', (value: string, exclude: string[]) => removeDiacritics(value, exclude).toUpperCase());
