import { Model } from '@vuex-orm/core';

export class KycProviderDetail extends Model {
    static entity = 'kycProviderDetail';

    static fields() {
        return {
            id: this.attr(null),
            type: this.attr(null),
            name: this.attr(null),
            uri: this.attr(null),
            strict: this.boolean(false),
            active: this.boolean(false)
        };
    }
}
