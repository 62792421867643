import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import merge from 'deepmerge';

import database, { State } from './database';
import {
    createPersist,
    repCreatePersist,
    invitationCreatePersist,
    navigationCreatePersist,
    kycProviderCreatePersist,
    kybProviderCreatePersist,
    accountManagementCreatePersist
} from './persist';
import { i18n } from '@/core/i18n';
import { VuexOrmExtensions } from '@/core/vuex-orm-extensions';

export const MERGE_STATE = 'MERGE_STATE';
export const REPLACE_ENTITIES = 'REPLACE_ENTITIES';

Vue.use(Vuex);

VuexORM.use(VuexOrmExtensions.Plugins.VuexOrmRulesPlugin, {
    validator: new VuexOrmExtensions.Utilities.OrmVeeValidator()
});

const store = () => new Vuex.Store({
    plugins: [
        VuexORM.install(database),
        createPersist().plugin,
        navigationCreatePersist().plugin,
        i18n.Store.Persist.Culture,
        kycProviderCreatePersist().plugin,
        kybProviderCreatePersist().plugin,
        accountManagementCreatePersist().plugin,
        invitationCreatePersist().plugin,
        repCreatePersist().plugin
    ],
    mutations: {
        [MERGE_STATE]: (state: State, payload) => {
            const entities = merge(state.entities, payload.entities, {
                arrayMerge(state, payload) {
                    return payload;
                },
                clone: false
            });

            Vue.set(state, 'entities', entities);
        },
        [REPLACE_ENTITIES]: (state: State, payload) => {
            const { entities } = state;

            for (const entity in payload.entities) {
                entities[entity] = payload.entities[entity];
            }

            Vue.set(state, 'entities', entities);
        }
    }
});

export default store;
