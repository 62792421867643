import bundle from '@/utilities/bundle';

export const INVITATION_ROOT_PATH = '/invite/:invitationType/:invitationId';

export const INVITATION_START_ROUTE = {
    path: '/',
    name: 'invite',
    component: bundle.loadView('invitation/start/start'),
    props: true
};

export const INVITATION_PRE_REGISTRATION_ROUTE = {
    path: '/invite/:invitationType',
    name: 'invite-pre-registration',
    component: bundle.loadView('invitation/pre-registration/pre-registration'),
    props: true
};

export const INVITATION_POST_REGISTRATION_ROUTE = {
    path: '/business',
    alias: '/business/*',
    name: 'business-post-registration',
    component: bundle.loadView('invitation/post-registration/post-registration')
};

export const INVITATION_BASE_ROUTES = {
    path: INVITATION_ROOT_PATH,
    children: [
        INVITATION_START_ROUTE
    ]
};
