import { extend } from 'vee-validate';

import { isYearAfter } from '@/core/rules';

extend('year_after', {
    validate(value, { year }: any) {
        return isYearAfter(value, year);
    },
    params: ['year']
});
