import { withPrefix } from '@/utilities/route';

import { REDIRECT_ROUTE } from '@/routes/redirect';
import { error } from '@/routes/error';
import { process } from '@/routes/process';
// import { BUSINESS_ROUTES } from '@/routes/business';
import { CLIENT_ROUTES } from '@/routes/client';
import { ASSOCIATE_ROUTES } from '@/routes/associated';
import { AFFILIATE_ROUTES } from '@/routes/affiliate';
import { KYC_PROVIDER_ROUTES, kycProviderReview } from '@/routes/kyc-provider';
import { AUTHENTICATION_ROUTES } from '@/routes/authentication';
// import { EXPRESS_CLIENT_ROUTES } from '@/routes/express-client';
// import { EXPRESS_BUSINESS_ROUTES } from '@/routes/express-business';
// import { EXPRESS_LANDING_PAGE_ROUTES } from '@/routes/express-landing-page';
import { ACCOUNT_MANAGEMENT_ROUTES } from '@/routes/account-management';
import { INVITATION_ROUTES } from '@/routes/invitation';
import { ADD_PAYOUT_DETAILS_ROUTES } from '@/routes/payout-details';
import { REP_ROUTES } from '@/routes/rep';

export const routes = [
    ...AUTHENTICATION_ROUTES,
    ...CLIENT_ROUTES,
    ...withPrefix('', kycProviderReview),
    ...withPrefix(error.path, error.children),
    ...withPrefix(KYC_PROVIDER_ROUTES.path, KYC_PROVIDER_ROUTES.children),
    // ...withPrefix(BUSINESS_ROUTES.path, BUSINESS_ROUTES.children),
    ...withPrefix(ASSOCIATE_ROUTES.path, ASSOCIATE_ROUTES.children),
    ...withPrefix(AFFILIATE_ROUTES.path, AFFILIATE_ROUTES.children),
    ...withPrefix(process.path, process.children),
    // ...withPrefix(EXPRESS_CLIENT_ROUTES.path, EXPRESS_CLIENT_ROUTES.children),
    // ...withPrefix(EXPRESS_LANDING_PAGE_ROUTES.path, EXPRESS_LANDING_PAGE_ROUTES.children),
    // ...withPrefix(EXPRESS_BUSINESS_ROUTES.path, EXPRESS_BUSINESS_ROUTES.children),
    ...ACCOUNT_MANAGEMENT_ROUTES,
    ...INVITATION_ROUTES,
    ...ADD_PAYOUT_DETAILS_ROUTES,
    ...REP_ROUTES,
    REDIRECT_ROUTE
];
