import { Model } from '@vuex-orm/core';

import { VuexOrmExtensions } from '@/core/vuex-orm-extensions';

const { trim } = VuexOrmExtensions.Utilities;

export class InvitationOperatingAddress extends Model {
    static entity = 'invitationOperatingAddress';

    static fields() {
        return {
            id: this.attr(null),
            street: this.attr('', (value) => trim(value)),
            number: this.attr('', (value) => trim(value)),
            postCode: this.attr('', (value) => trim(value)),
            city: this.attr('', (value) => trim(value)),
            countryCode: this.attr(''),
            secondLine: this.attr('', (value) => trim(value)),
            siret: this.attr(''),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }
}
