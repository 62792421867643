import { Store } from 'vuex';
import { Database } from '@vuex-orm/core';

import * as Models from './models';
import * as Modules from './modules';
import { i18n } from '@/core/i18n';

const database = new Database();

database.register(Models.Navigation, Modules.navigation);
database.register(Models.Client, Modules.client);
database.register(Models.UserAuth, Modules.userAuth);
database.register(Models.Address, Modules.address);
database.register(Models.OperatingAddress, Modules.operatingAddress);
database.register(Models.Merchant, Modules.merchant);
database.register(Models.LabCbd, Modules.labCbd);
database.register(Models.AssociatedPerson, Modules.associatedPerson);
database.register(Models.AssociatedPersonAddress, Modules.associatedPersonAddress);
database.register(Models.KycProviderDetail, Modules.kycProviderDetail);
database.register(Models.KycProviderClient, Modules.kycProviderClient);
database.register(Models.KycProviderAddress, Modules.kycProviderAddress);
database.register(Models.KybProviderReference, Modules.kybProviderReference);

/**
 * Customer data
 */
database.register(Models.AccountCustomerData, Modules.accountCustomerData);
database.register(Models.ClientCustomerData, Modules.clientCustomerData);
database.register(Models.MerchantCustomerData, Modules.merchantCustomerData);

/**
 * Invitation
 */
database.register(Models.Invitation, Modules.invitation);
database.register(Models.InvitationAddress, Modules.invitationAddress);
database.register(Models.InvitationClient, Modules.invitationClient);
database.register(Models.InvitationContact, Modules.invitationContact);
database.register(Models.InvitationLabCbd, Modules.invitationLabCbd);
database.register(Models.InvitationMerchant, Modules.invitationMerchant);
database.register(Models.InvitationOperatingAddress, Modules.invitationOperatingAddress);
database.register(Models.InvitationOrder, Modules.invitationOrder);
database.register(Models.InvitationProduct, Modules.invitationProduct);
database.register(Models.InvitationUI, Modules.invitationUI);

/**
 * Rep
 */
database.register(Models.Rep, Modules.rep);

database.register(i18n.Store.Models.Culture, i18n.Store.Modules.culture);

export interface PersistEntities {
    client: Models.Client,
    labCbd: Models.LabCbd,
    address: Models.Address,
    merchant: Models.Merchant,
    navigation: Models.Navigation,
    operatingAddress: Models.OperatingAddress,
    kycProviderClient: Models.KycProviderClient,
    kycProviderAddress: Models.KycProviderAddress,
    accountCustomerData: Models.AccountCustomerData,
    clientCustomerData: Models.ClientCustomerData,
    merchantCustomerData: Models.MerchantCustomerData,
    kybProviderReference: Models.KybProviderReference,
    invitation: Models.Invitation,
    invitationClient: Models.InvitationClient,
    invitationMerchant: Models.InvitationMerchant,
    invitationOrder: Models.InvitationOrder,
    invitationUI: Models.InvitationUI,
    invitationProduct: Models.InvitationProduct,
    invitationAddress: Models.InvitationAddress,
    invitationLabCbd: Models.InvitationLabCbd,
    invitationContact: Models.InvitationContact,
    invitationOperatingAddress: Models.InvitationOperatingAddress,
    rep: Models.Rep
}

export interface State extends Store<any>{
    entities: PersistEntities
}

export default database;
