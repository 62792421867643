export const WEB_BROWSER = 'web';
export const WEBVIEW_BROWSER = 'webview';
export const SAFARI_IN_APP_BROWSER = 'insafari';
export const GOOGLE_CUSTOM_TAB = 'ctgoogle';
export const EXTERNAL_BROWSER = 'extbrowser';

export const DEFAULT_BROWSER = WEB_BROWSER;

export const BROWSERS = [
    WEB_BROWSER,
    WEBVIEW_BROWSER,
    SAFARI_IN_APP_BROWSER,
    GOOGLE_CUSTOM_TAB,
    EXTERNAL_BROWSER
];

export const BROWSER_INDEX = {
    [WEB_BROWSER]: 0,
    [WEBVIEW_BROWSER]: 1,
    [SAFARI_IN_APP_BROWSER]: 2,
    [GOOGLE_CUSTOM_TAB]: 3,
    [EXTERNAL_BROWSER]: 4
};
