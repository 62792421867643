import { Base64 } from "js-base64";
import { Transform } from "./transform";

export class Base64Transform implements Transform {
    hash(key: string) {
        try {
            return Base64.encode(key);
        } catch (e) {}

        return '';
    }

    encrypt(key: string, value: string) {
        try {
            return Base64.encode(value);
        } catch (e) {}

        return null;
    }

    decrypt(key: string, value: string) {

        try {
            return Base64.decode(value);
        } catch (e) {}

        return null;
    }
}
