import bundle from '@/utilities/bundle';

export const AUTHENTICATION_POST_LOGIN_ROUTE = {
    path: '/plr',
    name: 'plr',
    component: bundle.loadView('authentication/post-login-redirect'),
    props: true,
    meta: {
        title: 'signUp'
    }
};

export const AUTHENTICATION_POST_LOGOUT_ROUTE = {
    path: '/plor',
    name: 'plor',
    component: bundle.loadView('authentication/post-logout-redirect'),
    props: true,
    meta: {
        title: 'signUp'
    }
};

export const AZURE_AUTHENTICATION_LOGIN_ROUTE = {
    path: '/ad-plr',
    name: 'ad-plr',
    component: bundle.loadView('authentication/ad-post-login-redirect'),
    props: true,
    meta: {
        title: 'signUp'
    }
};

export const AUTHENTICATION_ROUTES = [
    AUTHENTICATION_POST_LOGIN_ROUTE,
    AUTHENTICATION_POST_LOGOUT_ROUTE,
    AZURE_AUTHENTICATION_LOGIN_ROUTE
];
