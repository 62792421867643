import api, { auth } from './config';
import Services, { ServicePromise } from '@/utilities/services';

export default {
    activateDevice(data: any): ServicePromise<any> {
        const services = new Services(api(), auth());
        const url = '/devices:activate';

        return services.postData(url, data);
    }
};
