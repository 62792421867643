import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Resources from '../utilities/resources';

@Component
export default class Culture extends Vue {
    get culture() {
        return Vue.$culture.getCulture();
    }

    getLang() {
        return Vue.$culture.getLang();
    }

    async setLang(lang: string) {
        Vue.$culture.setLang(lang);
    }

    async hasResource(namespace: string, lang: string = this.getLang()) {
        return await Resources.hasResource(lang, namespace);
    }

    async loadResources(namespace: string|Array<string>) {
        await Resources.loadResources(namespace);
    }
}
