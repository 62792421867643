import Vue from "vue";
import * as Cookies from 'js-cookie';

import FingerPrintStorage from "./fingerprint-storage";
import { Transform } from "../../utilities";

export default class FingerPrintCookieStorage implements FingerPrintStorage {
    constructor(readonly expires: number, readonly transform: Transform) {}

    checkExpiration(key: string) {}

    getItem(key) {
        const fingerPrint = Vue.$fingerPrint.get();

        if (fingerPrint) {
            try {
                const itemKey = `${key}-${fingerPrint.id}`;
                const state = Cookies.get(itemKey);

                return state ? this.transform.decrypt(itemKey, state) : null;
            } catch (e) {}
        }

        return null;
    }

    setItem(key, value) {
        const fingerPrint = Vue.$fingerPrint.get();

        if (fingerPrint) {
            try {
                const itemKey = `${key}-${fingerPrint.id}`;
                const encrypt = this.transform.encrypt(itemKey, value);

                if (encrypt) {
                    Cookies.set(itemKey, encrypt, {expires: this.expires});
                }
            } catch (e) {}
        }
    }

    removeItem(key)  {
        const fingerPrint = Vue.$fingerPrint.get();

        if (fingerPrint) {
            Cookies.remove(`${key}-${fingerPrint.id}`)
        }
    }
}
