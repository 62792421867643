import { Base64 } from "js-base64";

interface FingerPrintPlugin {
    get(): FingerPrint | null;
    set(value: FingerPrint): void;
    remove(): void;
}

interface FingerPrintOptions {
    id: string,
    storage: Storage
}

export interface FingerPrint {
    id: string,
    type: string | number
}

declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $fingerPrint: FingerPrintPlugin
    }
}

export default class VueFingerPrint {
    static install (Vue, options: FingerPrintOptions) {
        const storage = options.storage;
        const id = options.id;

        Vue.$fingerPrint = {
            get(): FingerPrint | null {
                const fingerPrint = storage.getItem(id);

                if (!fingerPrint) {
                    return null;
                }

                try {
                    return JSON.parse(Base64.decode(fingerPrint));
                } catch(e) {}

                return null;
            },

            async set(fingerPrint: FingerPrint) {
                try {
                    storage.setItem(id, Base64.encode(JSON.stringify(fingerPrint)));
                } catch(e) {}
            },

            async remove() {
                storage.removeItem(id)
            }
        }
    }
}
