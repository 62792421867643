import { COUNTRY_CODES } from '@/constants/country-codes';

/*
 * Default postcode allows maximum 8 characters
 */
export const DEFAULT_POSTCODE_VALIDATION = /^.{1,8}$/;

export const POSTCODE_VALIDATIONS: { [key: string]: RegExp } = {
    [COUNTRY_CODES.Ireland]: /^\w{7}$/
};

export function isPostcodeValid(value: string, countryCode: string) {
    const regex = POSTCODE_VALIDATIONS[countryCode] ?? DEFAULT_POSTCODE_VALIDATION;

    return regex.test(value);
}
