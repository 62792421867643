import { Model } from '@vuex-orm/core';

export class AssociatedPersonAddress extends Model {
    static entity = 'associatedPersonAddress';

    static fields() {
        return {
            id: this.attr(null),
            street: this.attr(''),
            number: this.attr(''),
            postCode: this.attr(''),
            city: this.attr(''),
            countryCode: this.attr(''),
            secondLine: this.attr(''),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }
}
