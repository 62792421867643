import { extend } from 'vee-validate';

import { isLessThan } from '@/core/rules';

extend('total_shares', {
    validate(value, { actualValue, max }:any) {
        return isLessThan(value, { actualValue, max });
    },
    params: ['actualValue', 'max']
});
