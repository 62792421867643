import { Model } from '@vuex-orm/core';

export class Address extends Model {
    static entity = 'address';

    static fields() {
        return {
            id: this.attr(null),
            street: this.attr(''),
            number: this.attr(''),
            postCode: this.attr(''),
            city: this.attr(''),
            countryCode: this.attr(''),
            secondLine: this.attr(''),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }
}

export class AddressOptions {
    street: string;
    number: string;
    city: string;
    postCode: string;
    countryCode: string;
    secondLine: string;

    constructor(address?: Address) {
        if (address) {
            this.street = address.street;
            this.number = address.number;
            this.city = address.city;
            this.postCode = address.postCode;
            this.countryCode = address.countryCode;
            this.secondLine = address.secondLine;
        }
    }
}
