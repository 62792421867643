import { Model } from '@vuex-orm/core';

import { PayoutMethodType } from '@/constants/payout-method-type';
import { maskAccountNumber, formatBranchCode, maskIban } from '@/utilities/bank-account';
import { sanitizeIban } from '@/store/utilities/sanitize/iban';
import { UserAuth } from '@/store/models/userAuth';
import { RegistrationSystem } from '@/constants/registration-system';
import { RegistrationSource } from '@/constants/registration-source';
import { InvitationClient } from './invitation-client';
import { InvitationUI } from './invitation-ui';
import { InvitationMerchant } from './invitation-merchant';
import { InvitationOrder } from './invitation-order';

export class Invitation extends Model {
    static entity = 'invitation';

    static fields() {
        return {
            id: this.attr(null),
            client: this.morphOne(InvitationClient, 'modelId', 'modelType'),
            merchant: this.morphOne(InvitationMerchant, 'modelId', 'modelType'),
            order: this.morphOne(InvitationOrder, 'modelId', 'modelType'),
            userAuth: this.morphOne(UserAuth, 'modelId', 'modelType'),
            ui: this.morphOne(InvitationUI, 'modelId', 'modelType'),
            branding: this.attr(null),
            bankAccount: this.attr(null),
            payoutMethod: this.attr(PayoutMethodType.BankAccount),
            metadata: this.attr(''),
            registrationSystem: this.attr(RegistrationSystem.Default),
            registrationSource: this.attr(RegistrationSource.Online),
            returnUrl: this.attr(''),
            connectedMerchantId: this.attr(null),
            user: this.attr(null),
            strictlyPersonalAccountRequested: this.attr(false),
            clientIsLegalRepresentative: this.boolean(null),
            utmAttributes: this.attr(null)
        };
    }

    get userFullName() {
        return `${this.user?.firstName} ${this.user?.lastName}`.trim();
    }

    get formattedBranchCode() {
        return formatBranchCode(this.bankAccount?.branchCode);
    }

    get formattedIban() {
        if (!this.bankAccount?.iban) {
            return '';
        }

        const countryCode = this.merchant?.address?.countryCode || '';

        return sanitizeIban(this.bankAccount.iban, countryCode);
    }

    get maskedBankAccountNumber() {
        return maskAccountNumber(this.bankAccount?.accountNumber);
    }

    get maskedBankAccountIban() {
        return maskIban(this.formattedIban);
    }
}
