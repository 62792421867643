import { Model } from '@vuex-orm/core';

import {
    LabCbd,
    Address,
    AddressOptions,
    AssociatedPerson,
    OperatingAddress
} from '@/store/models';
import { RegistrationSystem } from '@/constants/registration-system';

export class Merchant extends Model {
    static entity = 'merchant';

    static fields() {
        return {
            id: this.attr(null),
            legalName: this.attr(null),
            tradeName: this.attr(null),
            registrationNumber: this.attr(null),
            vatNumberPrefix: this.attr(null),
            vatNumber: this.attr(null),
            taxNumber: this.attr(null),
            accessCode: this.attr(null),
            incorporationDate: this.attr(null),
            companyTypeId: this.attr(null),
            hasBearerShares: this.attr(null),
            isOffShore: this.attr(null),
            legalRepresentative: this.attr(null),
            merchantCategoryId: this.attr(null),
            merchantCategoryCode: this.attr(null),
            merchantCategoryName: this.attr(''),
            sourceOfFunds: this.attr(null),
            otherSourceOfFunds: this.attr(''),
            totalMonthlyValueOfDeposits: this.attr(null),
            maxNumberOfDepositsPerMonth: this.attr(null),
            maxSingleDeposit: this.attr(null),
            sourceOfFundsCategories: this.attr(null),
            stockExchange: this.attr(null), // stock exchange interface
            outsideActivity: this.attr(null), // outside activity interface
            transactionalProfile: this.attr(null), // transactional profile interface
            shareholdingStructure: this.attr(null),
            providerIdentifiers: this.attr(null),
            fatcaCrsLiabilityType: this.attr(null),
            legalFormType: this.attr(null),
            incomeType: this.attr(null),
            associatedPersons: this.morphOne(AssociatedPerson, 'modelId', 'modelType'),
            address: this.morphOne(Address, 'modelId', 'modelType'),
            additionalAddresses: this.attr(null),
            operatingAddress: this.morphOne(OperatingAddress, 'modelId', 'modelType'),
            labCbd: this.morphOne(LabCbd, 'modelId', 'modelType'),
            new: this.boolean(false),
            webPage: this.attr(null),
            webpageUnavailable: this.boolean(false),
            businessDescription: this.attr(null),
            accountNumber: this.attr(null),
            branchCode: this.attr(null),
            iban: this.attr(null),
            bankAccountConsent: this.boolean(false),
            businessTypeGroup: this.attr(null),
            registrationSystem: this.number(RegistrationSystem.Default),
            modelId: this.attr(null),
            modelType: this.attr(null),
            businessType: this.attr(null)
        };
    }
}

export class MerchantOptions {
    isOffShore: boolean;
    address: AddressOptions;
    hasBearerShares: boolean;
    sourceOfFunds: string;
    maxSingleDeposit: number;
    legalName: string;
    tradeName: string;
    merchantCategoryCode: string;
    registrationNumber: string;
    vatNumber: string;
    taxNumber: string;
    totalMonthlyValueOfDeposits: number;
    maxNumberOfDepositsPerMonth: number;
    incorporationDate: Date;
    companyTypeId: number;
    culture: string;
    personId?: string;
    webPage?: string;
    webpageUnavailable: boolean;
    businessType: string;

    constructor(merchant: Merchant) {
        this.companyTypeId = merchant.companyTypeId;
        this.registrationNumber = merchant.registrationNumber;
        this.vatNumber = merchant.vatNumber;
        this.taxNumber = merchant.taxNumber;
        this.merchantCategoryCode = merchant.merchantCategoryCode;
        this.tradeName = merchant.tradeName;
        this.legalName = merchant.legalName;
        this.totalMonthlyValueOfDeposits = merchant.totalMonthlyValueOfDeposits;
        this.maxNumberOfDepositsPerMonth = merchant.maxNumberOfDepositsPerMonth;
        this.maxSingleDeposit = merchant.maxSingleDeposit;
        this.isOffShore = merchant.isOffShore;
        this.hasBearerShares = merchant.hasBearerShares;
        this.incorporationDate = merchant.incorporationDate;
        this.webPage = merchant.webPage;
        this.webpageUnavailable = merchant.webpageUnavailable;
        this.businessType = merchant.businessType;

        if (merchant.address) {
            this.address = new AddressOptions(merchant.address);
        }
    }
}
