export const COMPANY_TYPES = {
    Unknown: 0,
    AE: 1,
    EPE: 2,
    OE: 3,
    EE: 4,
    IndividualBusiness: 5,
    Other: 6,
    NonProfit: 7,
    Institution: 8,
    Organization: 9,
    Freelance: 10,
    CivilAssociation: 11,
    ForeignCompany: 12,
    MKO: 13,
    IKE: 14,
    Individual: 15,
    PrivateOrPublicCompany: 16,
    CooperativeCompany: 17,
    SocietasEuropaea: 18,
    ForeignCompanyBranch: 19,
    BusinessName: 20,
    LegalEntity: 21,
    IndividualBusinessFreelanceSelfEmployed: 22,
    OffshoreCompany: 23,
    HeirsUnion: 24,
    Municipality: 25,
    SocietePriveeAResponsabiliteLimitee: 26,
    SocieteCooperativeAResponsabiliteLimitee: 27,
    SocieteEnCommanditeParActions: 28,
    SocieteEnCommanditeSimple: 29,
    SocieteEnNomCollectifBelgium: 30,
    Association: 31,
    PrivateCompanyLimitedByShares: 32,
    PrivateCompanyLimitedByGuarantee: 33,
    PublicLimitedCompany: 34,
    LimitedLiabilityPartnership: 35,
    SocietateCuRaspundereLimitata: 36,
    SocietatePeActiuni: 37,
    SocietateInNumeColectiv: 38,
    SocietateInComanditaSimpla: 39,
    IntreprindereIndividuala: 40,
    IntreprindereFamiliala: 41,
    SocieteAResponsabiliteLimitee: 42,
    SocieteParActionsSimplifiee: 43,
    SocieteEnNomCollectifFrance: 44,
    Succursale: 45,
    EntrepriseIndividuelle: 46,
    SocietaPerAzioni: 47,
    SocietaInAccomanditaPerAzioni: 48,
    SocietaAResponsabilitaLimitata: 49,
    SocietaInNomeCollettivo: 50,
    SocietaInAccomanditaSemplice: 51,
    SocietaCooperativaAResponsabilitaLimitata: 52,
    GBR: 53,
    ScottishLimitedPartnership: 54,
    Charity: 55,
    PubliclyListedCompany: 56,
    IndividualLimitedLiabilityEstablishment: 57,
    SoleProprietor: 58,
    Foundation: 59,
    JointStockCompany: 60,
    GmbhAndCoKg: 61,
    KommanditgesellschaftAufAktien: 62,
    EingetragenerVerein: 63,
    EingetrageneStiftung: 64,
    PartnerschaftsgesellschaftPartG: 65,
    NichtEingetragenerVerein: 66,
    EingetrageneKaufman: 67,
    OffeneGesellschaftOG: 68,
    EingetragenesUnternehmen: 69,
    EURL: 70,
    SELARL: 71,
    SASU: 72,
    SociedadAnonimaDeportiva: 73,
    SociedadCooperativa: 74,
    CooperatieOnderlingeWaarborgmaatschappij: 75,
    SpolkaCywilna: 76,
    SpolkaPartnerska: 77,
    SpolkaKomandytowoAkcyjna: 78,
    DzialalnoscRolnicza: 79,
    Spoldzielnia: 80,
    JavnoTrgovackoDrustvoJtD: 81,
    KomanditnoDrustvo: 82,
    DrugePravneOsobeEntitetIzjednacenSaPravnomOsobom: 83,
    AvoinYhtio: 84,
    SocietaSemplice: 85,
    EadEdnolichnoAktsionernoDruzhestvo: 86,
    PublichnoDruzhestvoEAD: 87,
    KdaKomanditnoDruzhestvoSAktsii: 88,
    IkkeErhvervsdrivendeFond: 89,
    SocieteCivile: 90,
    SocieteCooperativeSousFormeDeSocieteAnonyme: 91,
    SocieteCooperativeEuropeenne: 92,
    NyilvanosanMukoedoReszvenytarsasag: 93,
    Handelsbolag: 94,
    EkonomiskForening: 95,
    IndividualWithoutBusinessEstablishment: 96,
    Farmers: 97,
    IndividualCasualUse: 98,
    Embassy: 99,
    DevizakulfoeldiVallalkozasSzervezet: 100,
    EgyeniCeg: 101,
    Alapitvany: 102,
    Egyesulet: 103,
    MezogazdasagistermeloMezogazdasagiKistermelot: 104,
    TarsashazFelepitendoEpuletreUjonnanAlakuloTarsashaz: 105,
    TarsashazFennalloEpuletLegfeljebbLakasos: 106,
    TarsashazFennalloEpuletLakasnalNagyobb: 107,
    UegyvediIroda: 108,
    UegyvedEgyeni: 109,
    EingetrageneGenossenschaft: 110,
    EuropaischeWirtschaftlicheInteressenvereinigung: 111,
    eGbR: 112,
    WohnungseigentAmergemeinschaft: 113,
    ImpresaIndividuale: 114,
    MutuaAssicuratrice: 115,
    Consorzio: 116,
    Fondazione: 117,
    AssociazioneNonRiconosciuta: 118,
    Comitato: 119,
    EnteSociale: 120,
    BranchDiSocietaStraniera: 121,
    CooperativaSCPA: 122,
    Condominio: 123,
    PubblicaAmministrazione: 124,
    DomaceIStraneNeprofitneOrganizacijeINjihoveUdruge: 125,
    ZakladeIFundacije: 126,
    Ustanove: 127,
    UmjetnickeOrganzacije: 128,
    Komore: 129,
    Sindikati: 130,
    UdrugePoslodavaca: 131,
    PolitickeStranke: 132,
    Zadruge: 133,
    KreditneUnije: 134,
    VjerskeZajednice: 135,
    SociedadColectiva: 136,
    Obrti: 137,
    ObiteljskaPoljoprivrednaGospodarstva: 138,
    VennootschapOnderFirma: 139,
    Maatschap: 140,
    CommanditaireVennootschap: 141,
    Rederij: 142,
    Kerkgenootschap: 143,
    StichtingAdministratieKantoor: 144,
    Osuuskunta: 145,
    AsuntoOsakeyhtioe: 146,
    SocieteCooperative: 147,
    PublicSector: 148,
    SocieteSimple: 149,
    StateOwnedEnterprise: 150,
    GrupuriDeInteresEconomic: 151,
    SocietateProfesionalaDeAvocatiCuRaspundereLimitata: 152,
    SocietateProfesionalaNotariala: 153,
    IntreprindereProfesionalaUnipersonalaCuResponsabilitateLimitata: 154,
    CooperativaAgricola: 155,
    AltTip: 156,
    SociedadLimitadaUnipersonal: 157,
    SociedadLimitadaNuevaEmpresa: 158,
    SociedadLimitadaLaboral: 159,
    SociedadCivilUniversal: 160,
    SociedadCivilPrivada: 161,
    AgrupacionEuropeaDeInteresEconomico: 162,
    UnionTemporalDeEmpresas: 163,
    JointVentures: 164,
    Enkeltmandsvirksomhed: 165
};

export const INDIVIDUAL_COMPANY_TYPES: Array<number> = [
    COMPANY_TYPES.IndividualWithoutBusinessEstablishment,
    COMPANY_TYPES.IndividualCasualUse
];

export const FREELANCE_COMPANY_TYPES: Array<number> = [
    COMPANY_TYPES.IndividualBusiness,
    COMPANY_TYPES.Freelance,
    COMPANY_TYPES.IntreprindereIndividuala,
    COMPANY_TYPES.EntrepriseIndividuelle,
    COMPANY_TYPES.SoleProprietor,
    COMPANY_TYPES.EingetrageneKaufman,
    COMPANY_TYPES.EingetragenesUnternehmen,
    COMPANY_TYPES.DzialalnoscRolnicza,
    COMPANY_TYPES.IndividualBusinessFreelanceSelfEmployed
];

export const PROFESSIONAL_COMPANY_TYPES: Array<number> = [
    COMPANY_TYPES.IndividualBusiness,
    COMPANY_TYPES.Freelance,
    COMPANY_TYPES.IntreprindereIndividuala,
    COMPANY_TYPES.EntrepriseIndividuelle,
    COMPANY_TYPES.SoleProprietor,
    COMPANY_TYPES.EingetrageneKaufman,
    COMPANY_TYPES.EingetragenesUnternehmen,
    COMPANY_TYPES.DzialalnoscRolnicza,
    COMPANY_TYPES.IndividualBusinessFreelanceSelfEmployed,
    COMPANY_TYPES.MezogazdasagistermeloMezogazdasagiKistermelot,
    COMPANY_TYPES.Obrti,
    COMPANY_TYPES.ObiteljskaPoljoprivrednaGospodarstva,
    COMPANY_TYPES.Enkeltmandsvirksomhed,
    COMPANY_TYPES.Farmers
];

export const DEFAULT_COMPANY_TYPE = COMPANY_TYPES.Other;
