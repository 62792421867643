import { Model } from '@vuex-orm/core';

export class AccountCustomerData extends Model {
    static entity = 'accountCustomerData';

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            accountType: this.attr(null),
            pendencyStatus: this.attr(null),
            scopes: this.attr(null)
        };
    }
}
