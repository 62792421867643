import { extend } from 'vee-validate';

import { isValidURL } from '@/core/rules';

extend('url', {
    validate(value) {
        return isValidURL(value);
    },
    params: ['url']
});
