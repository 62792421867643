import { withPrefix } from '@/utilities/route';

import {
    INVITATION_BASE_ROUTES,
    INVITATION_POST_REGISTRATION_ROUTE,
    INVITATION_PRE_REGISTRATION_ROUTE
} from './base';
import { INVITATION_CLIENT_ROUTES } from './client';
import { INVITATION_BUSINESS_ROUTES } from './business';

export const INVITATION_ROUTES = [
    INVITATION_PRE_REGISTRATION_ROUTE,
    INVITATION_POST_REGISTRATION_ROUTE,
    ...withPrefix(INVITATION_BASE_ROUTES.path, INVITATION_BASE_ROUTES.children),
    ...withPrefix(INVITATION_CLIENT_ROUTES.path, INVITATION_CLIENT_ROUTES.children),
    ...withPrefix(INVITATION_BUSINESS_ROUTES.path, INVITATION_BUSINESS_ROUTES.children)
];
