import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
// import { NavigationStateOptions as StateOptions } from '@/constants';
import { State } from '@/store/database';

const StateOptions = {
    vuex: 'vuex-kyb-provider'
};

const persistStateOptions = {
    key: StateOptions.vuex,
    storage: {
        getItem: (key) => Vue.$sessionStorage.getItem(key),
        setItem: (key, value) => Vue.$sessionStorage.setItem(key, value),
        removeItem: (key) => Vue.$sessionStorage.removeItem(key),
    },
    reducer: (state: State) => {
        return {
            entities: {
                kybProviderReference: state.entities.kybProviderReference
            }
        };
    }
};

export function createPersist() {
    return {
        plugin: createPersistedState(persistStateOptions)
    };
}
