import trim from 'lodash/trim';

import { COUNTRY_CODES } from '@/constants/country-codes';

export function isAccountNumberHidden(countryCode: string) {
    return countryCode !== COUNTRY_CODES.GreatBritain;
}

export function isBranchCodeHidden(countryCode: string) {
    return countryCode !== COUNTRY_CODES.GreatBritain;
}

export function isIbanHidden(countryCode: string) {
    return countryCode === COUNTRY_CODES.GreatBritain;
}

export function supportsSepa(countryCode: string) {
    return countryCode !== COUNTRY_CODES.GreatBritain;
}

export function maskDetails(
    value: string,
    maskChar: string = '·',
    prefix: number = 4,
    suffix: number = 3
) {
    return [
        value.substring(0, prefix),
        maskChar.repeat(value.length - prefix - suffix),
        value.substring(value.length - suffix)
    ].join('');
}

export function maskIban(iban: string) {
    return iban ? maskDetails(iban) : '';
}

export function maskAccountNumber(accountNumber: string) {
    return accountNumber ? maskDetails(accountNumber, '·', 3, 2) : '';
}

export function formatBranchCode(branchCode: string, separator: string = '-') {
    return branchCode ? trim(branchCode.replace(/.{2}/g, `$&${separator}`), separator) : '';
}
