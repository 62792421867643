import * as Mixins from './mixins';
import * as Plugins from './plugins';
import * as Store from './store';
import * as Utilities from './utilities';

export const i18n = {
    Plugins,
    Store,
    Mixins,
    Utilities
};
