import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import DateInfo from '@/utilities/date-info';
import { i18n } from '@/core/i18n';

const ERROR_ALIAS = {
    over_age: 'date_format',
    year_before: 'year_after',
    account_number: 'numeric'
};

const ERROR_FIELD_ALIAS = {
    branchCode: {
        min: 'numeric'
    },
    directShares: 'shares',
    indirectShares: 'shares'
};

@Component({
    data() {
        return {
            dateInfo: new DateInfo()
        };
    }
})
export default class Internationalization extends mixins(i18n.Mixins.Culture) {
    dateInfo: DateInfo;

    created() {
        if (this.culture) {
            this.dateInfo = new DateInfo(this.culture.dateFormat);
        }
    }

    async checkLangParam() {
        if (this.$route.query.lang) {
            await this.setLang(this.$route.query.lang);
        }
    }

    @Watch('culture.dateFormat', { deep: true })
    setDateFormat(value, oldValue) {
        this.dateInfo = new DateInfo(value);
    }

    async loadErrorResources(lang: string) {
        await i18n.Utilities.Validator.loadErrorResources(lang, ERROR_ALIAS, ERROR_FIELD_ALIAS);
    }

    setPageTitle(isWebView?: boolean) {
        const title = !isWebView ? ['viva.com'] : [];

        if (this.$route.meta.title) {
            const pageTitle = this.$t(`account:${this.$route.meta.title}`);

            if (pageTitle && pageTitle !== this.$route.meta.title) {
                title.push(pageTitle);
            }
        }

        document.title = title.reverse().join(' - ');
    }

    @Watch('culture.lang', { deep: true })
    async changeCulture(value, oldValue) {
        await this.loadErrorResources(value);
    }
}
