import { extend } from 'vee-validate';

import { isDateFormat } from '@/core/rules';

extend('date_format', {
    validate(value, { format }: any) {
        return isDateFormat(value, format);
    },
    params: ['format']
});
