import '@babel/polyfill';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { setInteractionMode, localize } from 'vee-validate';
import VueI18Next from '@panter/vue-i18next';
import i18next from 'i18next';
import VueTheMask from 'vue-the-mask';
import App from '@/app';

import { routes } from '@/routes';
import { defaultLang, localeAlias, localeSettings, localizeOptions } from '@/constants';
import VueLocalStorage from '@/plugins/localstorage';
import VueSessionStorage from '@/plugins/sessionstorage';
import VueGoogleTagManager from '@/plugins/google-tag-manager';
import { Plugins as FingerPrintPlugins } from '@/core/fingerprint';
import { i18n } from '@/core/i18n';
import { store } from '@/store';
import { AppVuetify as vuetify } from '@/theme';
import '@/validations';

import '@/scss/site.scss';

require('@/core/i18n/moment/locales');

setInteractionMode('lazy');

localize(localizeOptions.culture);

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

Vue.use(VueLocalStorage);
Vue.use(VueSessionStorage);
Vue.use(FingerPrintPlugins.VueFingerPrint, {
    id: 'fingerprint',
    storage: window.sessionStorage
});
Vue.use(VueGoogleTagManager);
Vue.use(VueTheMask);
Vue.use(VueI18Next);

const vueI18Next = new VueI18Next(i18next);

Vue.use(i18n.Plugins.VueCulture, {
    id: 'registration',
    defaultLang,
    localeSettings,
    localeAlias,
    i18nextOptions: {
        lng: localizeOptions.culture,
        fallbackLng: localizeOptions.culture,
        ns: ['common', 'account', 'urls', 'invitation', 'errors', 'event-id'],
        backend: {
            loadPath: localizeOptions.path,
            queryStringParams: { v: process.env.VUE_APP_CACHE_VERSION }
        },
        missingKeyNoValueFallbackToKey: true
    }
});

Vue.config.devtools = true;

new Vue({
    router,
    vuetify,
    i18n: vueI18Next,
    store: store(),
    data: {},
    render: (h) => h(App)
}).$mount('#app-root');
