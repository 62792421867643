import bundle from '@/utilities/bundle';
import { join } from '@/utilities/url';

import { REP_ROOT_PATH } from './base';

export const REP_AUTH_EMPLOYEE_ROUTE = {
    path: '/employee',
    name: 'rep-auth-employee',
    component: bundle.loadView('rep/auth-employee/auth-employee'),
    props: true
};

export const REP_AUTH_PARTNER_CREDENTIALS_ROUTE = {
    path: '/partner',
    name: 'rep-auth-partner',
    component: bundle.loadView('rep/auth-partner/credentials/credentials'),
    props: true
};

export const REP_AUTH_PARTNER_OTP_ROUTE = {
    path: '/partner/otp',
    name: 'rep-auth-partner-otp',
    component: bundle.loadView('rep/auth-partner/otp-confirm/otp-confirm'),
    props: true
};

export const REP_AUTH_ROUTES = {
    path: join(REP_ROOT_PATH, 'auth'),
    children: [
        REP_AUTH_EMPLOYEE_ROUTE,
        REP_AUTH_PARTNER_CREDENTIALS_ROUTE,
        REP_AUTH_PARTNER_OTP_ROUTE
    ]
};
