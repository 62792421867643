export function sanitizeClientData(clientData: any) {
    const {
        legalName,
        firstName,
        lastName,
        email,
        mobileNumber,
        bankAccount,
        ...restClientData
    } = clientData;

    return restClientData;
}
