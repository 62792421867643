import { Model } from '@vuex-orm/core';

export class KycProviderAddress extends Model {
    static entity = 'kycProviderAddress';

    static fields() {
        return {
            id: this.attr(null),
            street: this.attr(''),
            number: this.attr(''),
            postCode: this.attr(''),
            city: this.attr(''),
            countryCode: this.attr(''),
            secondLine: this.attr(''),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }

    static rules() {
        return {
            number: 'required|max:10',
            street: 'required|max:75',
            postCode: 'required|max:8',
            city: 'required|max:25',
            countryCode: 'required',
            secondLine: 'max:40'
        }
    }
}
