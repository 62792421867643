import { Model } from '@vuex-orm/core';
import { Components, Options } from '@vuex-orm/core/lib/plugins/use';

import { OrmValidator, Context } from '../utilities';

export interface VuexOrmRulesOptions extends Options {
    validator?: OrmValidator
}

class RuleModel extends Model {
    static rules() {}
}

export class VuexOrmRules {
    constructor(components, options) {
        Context.setup(components, options);
        VuexOrmRules.setupModelMethods();
    }

    getContext(): Context {
        return Context.getInstance();
    }

    static setupModelMethods() {
        const context = Context.getInstance();
        const model = context.components.Model.prototype;
        const { validator } = context.options;

        model.$rules = function (field?: string) {
            try {
                const rules = (this.$self() as typeof RuleModel).rules();

                if (!field) {
                    return rules;
                }

                if (typeof rules[field] === 'function') {
                    return rules[field].call(null, this);
                }

                return rules[field];
            } catch (ex) {
                return null;
            }
        };

        model.$validate = function (field: string, rule?: string) {
            if (!validator) {
                return null;
            }

            const value = this[field];

            return validator.validate(value, rule || this.$rules(field));
        };

        model.$validateAll = async function (excluded?: Array<string>) {
            if (!validator) {
                return null;
            }

            const fields = this.$fields();
            const rules = this.$rules();

            const validations = {
                valid: true,
                fields: {}
            };

            for (const field in rules) {
                if (fields[field] && (excluded || []).indexOf(field) === -1) {
                    const fieldValidation = await this.$validate(field);

                    if (!fieldValidation.valid) {
                        validations.valid = false;
                    }

                    validations.fields[field] = fieldValidation;
                }
            }

            return validations;
        };
    }
}

export default class VuexOrmRulesPlugin {
    public static instance: VuexOrmRules;

    public static install(
        components: Components,
        options: VuexOrmRulesOptions
    ): VuexOrmRules {
        VuexOrmRulesPlugin.instance = new VuexOrmRules(components, options);

        return VuexOrmRulesPlugin.instance;
    }
}
