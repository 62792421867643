import { DurationInputObject } from 'moment';
import HttpStatus from 'http-status-codes';

import { ServicePromise, ServiceResponse } from '@/utilities/services';

export const VIVA_EVENT_ID: string = 'x-viva-eventid';
export const VIVA_CORRELATION_ID: string = 'x-viva-correlationid';

export interface CacheData {
    data: any,
    creation: Date
}

export interface CacheOptions {
    name: string
    expires?: boolean
    duration?: DurationInputObject
    forceUpdate?: boolean,
    cache?: boolean,
    storage?: Storage
}

export const SuccessHttpStatus: Array<number> = [
    HttpStatus.OK,
    HttpStatus.NO_CONTENT
];

export const UnauthorizedHttpStatus: Array<number> = [
    HttpStatus.UNAUTHORIZED
];

export default class PromiseHelper {
    isResponseSuccess(status: number): boolean {
        return SuccessHttpStatus.indexOf(status) > -1;
    }

    isResponseUnauthorized(status: number): boolean {
        return UnauthorizedHttpStatus.indexOf(status) > -1;
    }

    failPromise(failResponse?: ServiceResponse): ServicePromise<ServiceResponse<any>> {
        const failPromise = Promise.resolve(failResponse || {}) as ServicePromise;

        failPromise.then((response) => {
            response.success = false;
            if (response.headers) {
                response.eventId = response.headers[VIVA_EVENT_ID];
                response.correlationId = response.headers[VIVA_CORRELATION_ID];
            }
        });

        return failPromise;
    }

    successPromise(successResponse?: ServiceResponse): ServicePromise<ServiceResponse<any>> {
        const successPromise = Promise.resolve(successResponse || {}) as ServicePromise;

        successPromise.then((response) => {
            response.success = true;
            if (response.headers) {
                response.eventId = response.headers[VIVA_EVENT_ID];
                response.correlationId = response.headers[VIVA_CORRELATION_ID];
            }
        });

        return successPromise;
    }

    createPromise(data): ServicePromise<ServiceResponse<CacheData>> {
        const promise = Promise.resolve({
            data: data.data,
            status: HttpStatus.OK
        }) as ServicePromise;

        promise.then((response) => {
            response.success = true;
        });

        return promise;
    }
}
