import { extend } from 'vee-validate';

import { isOverAge } from '@/core/rules';

extend('over_age', {
    validate(value, { years, format }: any) {
        return isOverAge(value, years, format);
    },
    params: ['years', 'format']
});
