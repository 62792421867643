import { AES, enc, SHA256 } from 'crypto-js';
import { Transform } from "./transform";

export class CryptoTransform implements Transform {
    hash (key: string) {
        try {
            return SHA256(key).toString();
        } catch (e) {}

        return '';
    }

    encrypt(key: string, value: string) {
        try {
            const encrypt = AES.encrypt(JSON.stringify(value), key).toString();

            return encrypt;
        } catch (e) {}

        return null;
    }

    decrypt(key:string, value: string) {

        try {
            const bytes = AES.decrypt(value, key);

            return JSON.parse(bytes.toString(enc.Utf8));
        } catch (e) {}

        return null;
    }
}
