import { Model } from '@vuex-orm/core';

import { InvitationAddress } from './invitation-address';
import { InvitationProduct } from './invitation-product';
import { InvitationContact } from './invitation-contact';

export class InvitationOrder extends Model {
    static entity = 'invitationOrder';

    static fields() {
        return {
            id: this.attr(null),
            amount: this.attr(null),
            currencyCode: this.attr(null),
            paid: this.boolean(false),
            delivered: this.boolean(false),
            comments: this.attr(null),
            products: this.morphMany(InvitationProduct, 'modelId', 'modelType'),
            address: this.morphOne(InvitationAddress, 'modelId', 'modelType'),
            contact: this.morphOne(InvitationContact, 'modelId', 'modelType'),
            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }
}
