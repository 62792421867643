import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import {
    layoutOptions
} from '@/constants';
import '@/filters/uppercase';
import FormAttribute from '@/directives/form-attribute';

import Internationalization from '@/mixins/internationalization';
import Authentication from '@/mixins/authentication';
import Platform from '@/mixins/platform';
import { getUrlParameterByName } from '@/utilities/url';
import AppNavigation from '@/mixins/app-navigation';
import { storeActivationPosCode } from '@/utilities/pos';
import { initializeDatadog } from '@/core/datadog/datadog-rum';

FormAttribute.create({
    id: 'data-model-id'
});

@Component
export default class App extends mixins(
    Platform,
    AppNavigation,
    Authentication,
    Internationalization
) {
    async created() {
        const platform = getUrlParameterByName('platform');
        const browser = getUrlParameterByName('browser');

        this.setPlatform(platform);
        this.setBrowser(browser);
        this.loadLayout();

        const lang = this.$route.query.lang || this.getLang();

        await this.setLang(lang);

        if (this.isWebView) {
            this.setQueryParams({
                platform: this.platformIndex
            });
        }

        if (this.isPosPlatform) {
            storeActivationPosCode();
        }

        await initializeDatadog();

        await this.resetTheme();
    }

    get layout(): string {
        const layoutName = this.$route.meta.layout || layoutOptions.default;

        return `${layoutOptions.prefix}-${layoutName}`;
    }

    @Watch('$route')
    changeRoute(value, oldValue) {
        this.loadLayout();

        if (value !== oldValue && value.query.lang) {
            this.setLang(value.query.lang);
        }

        this.setPageTitle(this.isMobilePlatform);
    }

    loadLayout() {
        const layout = this.$route.meta.layout || layoutOptions.default;

        if (!this.$options.components) {
            this.$options.components = {};
        }

        if (!this.$options.components[`${layoutOptions.prefix}-${layout}`]) {
            this.$options.components[`${layoutOptions.prefix}-${layout}`]
                = Vue.component(
                    `${layoutOptions.prefix}-${layout}`,
                    () => import(`@/layouts/${layout}/index.vue`)
                );
        }
    }
}
