export enum AuthenticationScheme {
    Basic = 'Basic',
    Bearer = 'Bearer',
    Digest = 'Digest',
    Hoba = 'HOBA',
    Mutual = 'Mutual',
    Negotiate = 'Negotiate',
    OAuth = 'OAuth'
}

export interface Auth {
    scheme: AuthenticationScheme,
    credentials: string,
    settings?: any,
    init(): Promise<void>,
    isValid(): boolean
}
