import Vue from 'vue';
import each from 'lodash/each';
import extend from 'lodash/extend';
import axios from 'axios';
import { localize } from 'vee-validate';

type Alias = {
    [key: string]: string;
};

type FieldAlias = {
    [key: string]: {
        [key: string]: string;
    } | string;
};

export default {
    async loadErrorResources(lang: string, alias?: Alias, fieldsAliases?: FieldAlias) {
        const settings = Vue.$culture.getSettings(lang);
        let dictionary = {
            name: lang,
            messages: {},
            fields: {}
        };

        try {
            dictionary = await import(`vee-validate/dist/locale/${settings.validator || settings.culture}.json`);
        } catch (e) {
            console.log(e);
        }

        try {
            const instance = axios.create({
                baseURL: process.env.BASE_URL
                    ? process.env.BASE_URL
                    : '/'
            });

            const response = await instance.get(`/locales/${settings.culture}/errors.json`);
            const errors: any = response.data || {};

            const customResponse = await instance.get(`/locales/${settings.culture}/field-specific-errors.json`);
            const fields: Record<string, unknown> = (customResponse.data as Record<string, unknown>) || {};

            if (alias) {
                each(alias, (value, key) => {
                    errors[key] = errors[value];
                });
            }

            if (fieldsAliases && fields) {
                each(fieldsAliases, (value, key) => {
                    if (typeof value === 'string') {
                        fields[key] = fields[value];
                    } else {
                        const firstEntryKey = Object.keys(value)[0];

                        fields[key] = { [firstEntryKey]: errors[value[firstEntryKey]] };
                    }
                });
            }

            dictionary.fields = {};

            extend(dictionary.messages, errors);
            extend(dictionary.fields, fields);
        } catch (e) {
            console.log(e);
        }

        localize(lang, dictionary);
    }
};
