import bundle from '@/utilities/bundle';

export const ACCOUNT_MANAGEMENT_BUSINESS_START_ROUTE = {
    path: '/',
    name: 'account-management-business-start',
    component: bundle.loadView('account-management/business/start/start'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_LEGAL_INFO_ROUTE = {
    path: '/legal-info',
    name: 'account-management-business-legal-info',
    component: bundle.loadView('account-management/business/legal-info/legal-info'),
    props: true,
    meta: {
        title: 'headline.legalInfo'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_DATE_ESTABLISHED_ROUTE = {
    path: '/date-established',
    name: 'account-management-business-date-established',
    component: bundle.loadView('account-management/business/date-established/date-established'),
    props: true,
    meta: {
        title: 'headline.dateEstablished'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_IDENTIFIERS_ROUTE = {
    path: '/identifiers',
    name: 'account-management-business-identifiers',
    component: bundle.loadView('account-management/business/identifiers/identifiers'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_HEADQUARTERS_ROUTE = {
    path: '/headquarters',
    name: 'account-management-business-headquarters',
    component: bundle.loadView('account-management/business/headquarters/headquarters'),
    props: true,
    meta: {
        title: 'headline.headquarters'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_OPERATION_ADDRESS_ROUTE = {
    path: '/operating-address',
    name: 'account-management-business-operating-address',
    component: bundle.loadView('account-management/business/operating-address/operating-address'),
    props: true,
    meta: {
        title: 'headline.operatingAddress'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_SHAREHOLDING_STRUCTURE_ROUTE = {
    path: '/shareholding-structure',
    name: 'account-management-shareholding-structure',
    component: bundle.loadView('account-management/business/shareholding-structure/shareholding-structure'),
    props: true,
    meta: {
        title: 'headline.shareholdingStructure'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_STOCK_EXCHANGE_ROUTE = {
    path: '/stock-exchange',
    name: 'account-management-business-stock-exchange',
    component: bundle.loadView('account-management/business/stock-exchange/stock-exchange'),
    props: true,
    meta: {
        title: 'headline.stockExchange'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_ROUTE = {
    path: '/abroad',
    name: 'account-management-business-abroad',
    component: bundle.loadView('account-management/business/abroad/abroad'),
    props: true,
    meta: {
        title: 'headline.businessAbroad'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_OFFSHORE_ROUTE = {
    path: '/abroad/offshore-companies',
    name: 'account-management-offshore-companies',
    component: bundle.loadView('account-management/business/abroad/offshore-companies/offshore-companies'),
    props: true,
    meta: {
        title: 'headline.abroad.offshoreCompanies',
        stateless: true
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_BRANCHES_ROUTE = {
    path: '/abroad/branches',
    name: 'account-management-branches',
    component: bundle.loadView('account-management/business/abroad/branches/branches'),
    props: true,
    meta: {
        title: 'headline.abroad.branches',
        stateless: true
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_SUBSIDIARIES_ROUTE = {
    path: '/abroad/subsidiaries',
    name: 'account-management-subsidiaries',
    component: bundle.loadView('account-management/business/abroad/subsidiaries/subsidiaries'),
    props: true,
    meta: {
        title: 'headline.abroad.subsidiaries',
        stateless: true
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_REPRESENTATIVE_OFFICES_ROUTE = {
    path: '/abroad/representative-offices',
    name: 'account-management-representative-offices',
    component: bundle.loadView('account-management/business/abroad/representative-offices/representative-offices'),
    props: true,
    meta: {
        title: 'headline.abroad.representationOffices',
        stateless: true
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_PARENT_COMPANY_ROUTE = {
    path: '/abroad/parent-company',
    name: 'account-management-parent-company',
    component: bundle.loadView('account-management/business/abroad/parent-company/parent-company'),
    props: true,
    meta: {
        title: 'headline.abroad.parentCompany',
        stateless: true
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_TRANSACTIONAL_ROUTE = {
    path: '/transactional',
    name: 'account-management-business-transactional',
    component: bundle.loadView('account-management/business/transactional/transactional'),
    props: true,
    meta: {
        title: 'headline.transactional'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_SOURCE_OF_FUNDS_ROUTE = {
    path: '/source-of-funds',
    name: 'account-management-business-source-of-funds',
    component: bundle.loadView('account-management/business/source-of-funds/source-of-funds'),
    props: true,
    meta: {
        title: 'headline.financialProfile'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_FATCA_CRS_ROUTE = {
    path: '/fatca-crs',
    name: 'account-management-business-fatca-crs',
    component: bundle.loadView('account-management/business/fatca-crs/fatca-crs'),
    props: true,
    meta: {
        title: 'headline.fatcaCrs'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_FATCA_CRS_INCOME_ROUTE = {
    path: '/fatca-crs/income',
    name: 'account-management-business-fatca-crs-income',
    component: bundle.loadView('account-management/business/fatca-crs/income/income'),
    props: true,
    meta: {
        title: 'headline.activePassive',
        stateless: true
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_FATCA_CRS_LEGAL_FORM_ROUTE = {
    path: '/fatca-crs/legal-form',
    name: 'account-management-business-fatca-crs-legal-form',
    component: bundle.loadView('account-management/business/fatca-crs/legal-form/legal-form'),
    props: true,
    meta: {
        title: 'headline.businessType',
        stateless: true
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_FINANCIAL_PROFILE_ROUTE = {
    path: '/financial-profile',
    name: 'account-management-business-financial-profile',
    component: bundle.loadView('account-management/business/financial-profile/financial-profile'),
    props: true,
    meta: {
        title: 'headline.financialProfile'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_CONSENT_ROUTE = {
    path: '/consent',
    name: 'account-management-business-consent',
    component: bundle.loadView('account-management/business/consent/consent'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_PROCESS_ROUTE = {
    path: '/process',
    name: 'account-management-business-process',
    component: bundle.loadView('account-management/business/process/process'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_SUCCESS_ROUTE = {
    path: '/success',
    name: 'account-management-business-success',
    component: bundle.loadView('account-management/business/success/success'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_ONLINE_PRESENCE_ROUTE = {
    path: '/online-presence',
    name: 'account-management-business-online-presence',
    component: bundle.loadView('account-management/business/online-presence/online-presence'),
    props: true,
    meta: {
        title: 'headline.onlinePresence'
    }
};

export const ACCOUNT_MANAGEMENT_BUSINESS_ROUTES = {
    path: '/account/business/:id/:scope',
    children: [
        ACCOUNT_MANAGEMENT_BUSINESS_START_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_LEGAL_INFO_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_DATE_ESTABLISHED_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_IDENTIFIERS_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_HEADQUARTERS_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_ONLINE_PRESENCE_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_OPERATION_ADDRESS_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_SHAREHOLDING_STRUCTURE_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_STOCK_EXCHANGE_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_BRANCHES_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_PARENT_COMPANY_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_SUBSIDIARIES_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_OFFSHORE_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_ABROAD_REPRESENTATIVE_OFFICES_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_TRANSACTIONAL_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_SOURCE_OF_FUNDS_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_FATCA_CRS_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_FATCA_CRS_INCOME_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_FATCA_CRS_LEGAL_FORM_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_FINANCIAL_PROFILE_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_CONSENT_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_PROCESS_ROUTE,
        ACCOUNT_MANAGEMENT_BUSINESS_SUCCESS_ROUTE
    ]
};
