import { withMdIconComponent } from '@/core/icons';

import {
    mdiEye,
    mdiEyeOff,
    mdiTrashCan,
    mdiHome,
    mdiCircleOutline,
    mdiCheckCircle,
    mdiCheckboxMarked,
    mdiChevronLeft,
    mdiChevronRight,
    mdiWeb,
    mdiCheckboxBlankOutline,
    mdiCircle,
    mdiCloseCircle,
    mdiPlus
} from '@mdi/js';
import { VuetifyIconComponent } from 'vuetify/types/services/icons';

export const MdAppIcons: { [key: string]: VuetifyIconComponent } = {
    'app-eye': withMdIconComponent(mdiEye),
    'app-eye-off': withMdIconComponent(mdiEyeOff),
    'app-trash': withMdIconComponent(mdiTrashCan),
    'app-home': withMdIconComponent(mdiHome),
    'app-circle-outline': withMdIconComponent(mdiCircleOutline),
    'app-check-circle': withMdIconComponent(mdiCheckCircle),
    'app-checkbox-marked': withMdIconComponent(mdiCheckboxMarked),
    'app-chevron-left': withMdIconComponent(mdiChevronLeft),
    'app-chevron-right': withMdIconComponent(mdiChevronRight),
    'app-web': withMdIconComponent(mdiWeb),
    'app-checkbox-blank-outline': withMdIconComponent(mdiCheckboxBlankOutline),
    'app-circle': withMdIconComponent(mdiCircle),
    'app-close-circle': withMdIconComponent(mdiCloseCircle),
    'app-plus': withMdIconComponent(mdiPlus)
};
