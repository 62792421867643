import { Model } from '@vuex-orm/core';

export class Rep extends Model {
    static entity = 'rep';

    static fields() {
        return {
            id: this.attr(null),
            invitationId: this.attr(null), // Invitation id
            sessionId: this.attr(null), // Session id
            repType: this.attr(null), // select Viva employee or Viva partner
            partnerDetails: this.attr(null), // partner Details: fullname, countryCode, mobile
            utmAttributes: this.attr(null),
            providerUrl: this.attr(null)
        };
    }
}
