import { Components, Options } from "@vuex-orm/core/lib/plugins/use";

export class Context {
    public static instance: Context;
    public readonly components: Components;
    public readonly options: Options;

    constructor(components, options) {
        this.components = components;
        this.options = options;
    }

    static setup(components, options) {
        this.instance = new Context(components, options);

        return this.instance;
    }

    static getInstance() {
        return this.instance;
    }
}
