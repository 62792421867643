import { extend } from 'vee-validate';

import { isPostcodeValid } from '@/core/rules';

extend('postcode', {
    validate(value, { countryCode }: any) {
        return isPostcodeValid(value, countryCode);
    },
    params: ['countryCode']
});
