import axios from 'axios';

import { IdentityAuth, AuthenticationScheme } from '@/core/authentication';

const SiteVersion: string = 'v1';

const VivaPaymentsPersons = () => (axios.create({
    baseURL: `${config.apiHost}/pos/${SiteVersion}`
}));

export const auth = () => (new IdentityAuth(
    AuthenticationScheme.Bearer,
    config.identityServerSettings,
    config.accessToken
));

export default VivaPaymentsPersons;
