import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { TranslationOptions } from 'i18next';
import Item from '@vuex-orm/core/lib/data/Item';

import { Navigation } from '@/store/models';
import { NavigationStateOptions } from '@/constants';
import Platform from '@/mixins/platform';
import Internationalization from '@/mixins/internationalization';
import { RegistrationSystem } from '@/constants/registration-system';
import { Colors } from '@/core/helpers';
import { applyThemeColor } from '@/core/helpers/theme';
import { getActivationDeviceDeepLink } from '@/utilities/pos';

interface SelfcareOptions {
    mobile?: string;
    merchantId?: string;
    countryCode?: string;
    mobilePrefix?: string;
}

@Component
export default class AppNavigation extends mixins(
    Platform,
    Internationalization
) {
    created() {
        this.setPageStyle();
        this.setPageTitle(this.isWebView);
    }

    get navigation(): Item {
        return this.getNavigation();
    }

    getNavigation(): Item {
        return Navigation.find(NavigationStateOptions.navigationIndex);
    }

    setPageStyle() {
        const className = ['page', `page-${this.$route.name}`];

        if (this.$route.meta.cssClass) {
            className.push(this.$route.meta.cssClass);
        }

        if (this.platform) {
            if (this.isWebView) {
                className.push('platform-webview');
            }

            className.push(`platform-${this.platform}`);
        }

        document.body.className = className.join(' ');
    }

    async saveLastRoute(options?) {
        if (!options) {
            options = {
                path: this.$route.path,
                params: this.$route.params,
                query: this.$route.query,
                name: this.$route.name,
                stateless: this.$route.meta?.stateless
            };
        }

        if (options.stateless) {
            return;
        }

        await Navigation.insertOrUpdate({
            data: {
                id: NavigationStateOptions.navigationIndex,
                lastRoute: options
            }
        });
    }

    restart(indexPage) {
        this.redirect({
            name: indexPage
        });
    }

    redirect(options) {
        this.$router.push(options);
    }

    goToErrorPage(statusCode: number) {
        this.$router.replace({
            name: statusCode.toString()
        });
    }

    goToExternalUrl(url: string) {
        window.location.href = url;
    }

    getUrl(path: string, options: TranslationOptions = {}) {
        options.interpolation = {
            escapeValue: false
        };

        return this.$t(`urls:${path}`, options);
    }

    getMobileAppWebViewUrl(host?: string, options?: SelfcareOptions) {
        if (!host) {
            return null;
        }

        if (options?.merchantId) {
            return `${host}/business_account?selectedAccountId=${options.merchantId}`;
        }

        const mobile = options?.mobile || '';
        const countryCode = options?.countryCode || '';
        const mobilePrefix = options?.mobilePrefix || '';

        return `${host}/login/details?number=${mobile}&phoneCode=${mobilePrefix}&countryCode=${countryCode}`;
    }

    getPosAppWebViewUrl() {
        const activationDeviceDeepLink = getActivationDeviceDeepLink();

        if (activationDeviceDeepLink) {
            return activationDeviceDeepLink;
        }

        return null;
    }

    getWebViewRedirectUrl(options?: SelfcareOptions) {
        if (this.isPosPlatform) {
            return this.getPosAppWebViewUrl;
        }

        if (this.isGoogleCustomTab || this.isSafariInAppBrowser) {
            return this.getMobileAppWebViewUrl(process.env.VUE_APP_CUSTOM_TAB_DEEPLINK, options);
        }

        if (this.isMobilePlatform) {
            return this.getMobileAppWebViewUrl(process.env.VUE_APP_WEBVIEW_DEEPLINK, options);
        }

        return this.getMobileAppWebViewUrl(process.env.VUE_APP_DEEPLINK_HOST, options);
    }

    getSelfcareUrl(options?: SelfcareOptions, redirectUrl?: string) {
        if (this.isWebView) {
            return this.getWebViewRedirectUrl(options);
        }

        if (redirectUrl) {
            return redirectUrl;
        }

        return this.getUrl('selfcare.home', { host: config.selfcareHost });
    }

    goToSelfcare(options?: SelfcareOptions, redirectUrl?: string) {
        this.goToExternalUrl(this.getSelfcareUrl(options, redirectUrl));
    }

    get registrationSystem() {
        if (this.isMobilePlatform) {
            return RegistrationSystem.BankingApp;
        }

        if (this.isPosPlatform) {
            return RegistrationSystem.PosApp;
        }

        return RegistrationSystem.Default;
    }

    async resetTheme() {
        const navigation = this.getNavigation();

        if (navigation) {
            navigation.themeApplied = false;

            await navigation.$update(navigation);
        }
    }

    applyThemeColor(primaryColor: string) {
        const navigation = this.getNavigation();

        if (navigation?.themeApplied) {
            return;
        }

        if (Colors.isHexColorValid(primaryColor)) {
            applyThemeColor(primaryColor);
        }

        if (navigation) {
            navigation.themeApplied = true;
            navigation?.$update(navigation);
        }
    }

    switchFromLocalToSessionStorage(key: string) {
        const value = Vue.$localStorage.getItem(key);

        if (value === null) {
            return;
        }

        Vue.$sessionStorage.setItem(key, value);
        Vue.$localStorage.removeItem(key);
    }
}
