import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';

import { State } from '@/store/database';

const StateOptions = {
    vuex: 'vuex-invitation'
};

const persistStateOptions = {
    key: StateOptions.vuex,
    storage: {
        getItem: (key) => Vue.$sessionStorage.getItem(key),
        setItem: (key, value) => Vue.$sessionStorage.setItem(key, value),
        removeItem: (key) => Vue.$sessionStorage.removeItem(key)
    },
    reducer: (state: State) => ({
        entities: {
            invitation: state.entities.invitation,
            invitationClient: state.entities.invitationClient,
            invitationMerchant: state.entities.invitationMerchant,
            invitationOrder: state.entities.invitationOrder,
            invitationUI: state.entities.invitationUI,
            invitationProduct: state.entities.invitationProduct,
            invitationAddress: state.entities.invitationAddress,
            invitationLabCbd: state.entities.invitationLabCbd,
            invitationContact: state.entities.invitationContact,
            invitationOperatingAddress: state.entities.invitationOperatingAddress
        }
    })
};

export function createPersist() {
    return {
        plugin: createPersistedState(persistStateOptions)
    };
}

export function removePersist() {
    Vue.$sessionStorage.removeItem('vuex-invitation');
}
