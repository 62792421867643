import moment from 'moment';

interface DateInfo {
    format: string;
    mask: string;
}

class DateInfo implements DateInfo {
    constructor(culture?: string) {
        const localeMoment = moment();

        if (culture) {
            localeMoment.locale(culture);
        }

        const localeData = localeMoment.localeData();

        this.format = localeData.longDateFormat('L').replace(/\//g, '-');
        this.mask = this.format.replace(/[a-zA-Z]/g, '#');
    }
}

export default DateInfo;
