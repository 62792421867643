import bundle from '@/utilities/bundle';

export const error = {
    path: '/error',
    children: [
        {
            path: '/401',
            name: '401',
            component: bundle.loadView('errors/401/401'),
            props: true,
            meta: {
                title: ''
            }
        }
    ]
};
