import { extend } from 'vee-validate';

import { isYearBefore } from '@/core/rules';

extend('year_before', {
    validate(value, { year }: any) {
        return isYearBefore(value, year);
    },
    params: ['year']
});
