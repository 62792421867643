import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import {
    BROWSER_INDEX,
    BROWSERS,
    DEFAULT_BROWSER,
    EXTERNAL_BROWSER,
    GOOGLE_CUSTOM_TAB,
    SAFARI_IN_APP_BROWSER,
    WEBVIEW_BROWSER
} from '@/constants/browser';

@Component
export default class Browser extends Vue {
    get browser() {
        let browser = Vue.$sessionStorage.getItem('browser');

        if (this.$route.query.browser) {
            browser = this.$route.query.browser;
        }

        return browser && this.isValidBrowser(browser)
            ? browser
            : DEFAULT_BROWSER;
    }

    get browserIndex() {
        return BROWSER_INDEX[this.browser] || BROWSER_INDEX[DEFAULT_BROWSER];
    }

    isValidBrowser(browser: string) {
        return BROWSERS.indexOf(browser) > -1;
    }

    setBrowser(browser?: string | null) {
        if (!browser) {
            browser = this.$route.query.browser;
        }

        if (this.isValidBrowser(browser)) {
            Vue.$sessionStorage.setItem('browser', browser);
        }
    }

    get isMobileBrowser() {
        return BROWSERS.indexOf(this.browser) > 0;
    }

    get isWebViewBrowser() {
        return this.browser === WEBVIEW_BROWSER;
    }

    get isSafariInAppBrowser() {
        return this.browser === SAFARI_IN_APP_BROWSER;
    }

    get isGoogleCustomTab() {
        return this.browser === GOOGLE_CUSTOM_TAB;
    }

    get isExternalBrowser() {
        return this.browser === EXTERNAL_BROWSER;
    }
}
