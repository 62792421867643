import { sanitizeIban } from '@/store/utilities/sanitize/iban';

export function isIbanValid(value: string, countryCode: string) {
    const iban = value.replace(/\s/g, '');

    if (!countryCode) {
        return /^[a-zA-Z]{2}[a-zA-Z0-9]*$/.test(iban);
    }

    const pattern = `^(${countryCode})[a-zA-Z0-9]*$`;
    const regex = new RegExp(pattern, 'i');

    return regex.test(sanitizeIban(iban, countryCode));
}
