export enum RegistrationSource {
    Unknown = 0,
    Online = 1,
    Telesales = 11,
    SalesOnField = 12,
    DirectSales = 13,
    LeadGenerators = 14,
    Affiliates = 21,
    Marketplace = 23,
    VivaCom = 24
}
