import { Model } from '@vuex-orm/core';

export class Navigation extends Model {
    static entity = 'navigation';

    static fields() {
        return {
            id: this.attr(null),
            lastRoute: this.attr(null),
            platform: this.attr(null),
            themeApplied: this.attr(false)
        };
    }
}
