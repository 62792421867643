export function getUrlParameterByName(name: string, url?: string) {
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, '\\$&');

    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function join(root: string, path: string) {
    const baseUrl = root.replace(/\/$/, '');

    return `${baseUrl}/${path.replace(/^\/+/, '')}`;
}
