import { Model } from '@vuex-orm/core';

export class InvitationUI extends Model {
    static entity = 'invitationUI';

    static fields() {
        return {
            id: this.attr(null),
            isClientValid: this.boolean(true),
            isMerchantValid: this.boolean(true),
            isDeliveryValid: this.boolean(true),
            isStepPaymentValidated: this.boolean(false),
            isStepAuthValidated: this.boolean(false),
            showSaveMessage: this.boolean(false),
            preRegister: this.boolean(false),
            postRegister: this.boolean(false),

            // Invitation settings
            isStepBusinessAccountCompleted: this.boolean(false),
            isStepBusinessPayoutCompleted: this.boolean(false),
            isStepBusinessOrderDeliveryCompleted: this.boolean(false),

            bankAccountConsent: this.boolean(false),
            mobile: this.attr(null),

            modelId: this.attr(null),
            modelType: this.attr(null)
        };
    }
}
