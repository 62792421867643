import { Auth, AuthenticationScheme, TokenService } from "@/core/authentication";

export class IdentityTokenAuth implements Auth {
    credentials: string;

    constructor(
        readonly scheme: AuthenticationScheme,
        readonly tokenService: TokenService) {
    }

    async init() {
        const response = await this.tokenService.getToken();

        if (response.success) {
            this.credentials = response.data.access_token || '';
        }
    }

    isValid() {
        return this.scheme && this.credentials !== '';
    }
}

