import { extend } from 'vee-validate';

import { isAmountValid } from '@/core/currency/rules';

extend('currency', {
    validate(value, { culture }: any) {
        return isAmountValid(value, culture);
    },
    params: ['culture']
});
