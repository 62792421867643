import { Model } from '@vuex-orm/core';

import { VuexOrmExtensions } from '@/core/vuex-orm-extensions';
import { RegistrationSystem } from '@/constants/registration-system';
import { InvitationLabCbd } from './invitation-lab-cbd';
import { InvitationAddress } from './invitation-address';
import { InvitationOperatingAddress } from './invitation-operating-address';

const { trim } = VuexOrmExtensions.Utilities;

export class InvitationMerchant extends Model {
    static entity = 'invitationMerchant';

    static fields() {
        return {
            id: this.attr(null),
            legalName: this.attr('', (value) => trim(value)),
            tradeName: this.attr('', (value) => trim(value)),
            registrationNumber: this.attr('', (value) => trim(value)),
            vatNumberPrefix: this.attr(null),
            vatNumber: this.attr('', (value) => trim(value)),
            taxNumber: this.attr('', (value) => trim(value)),
            accessCode: this.attr('', (value) => trim(value)),
            incorporationDate: this.attr(null), // Pass-through incorporation date from sales portal response
            companyTypeId: this.attr(null),
            legalRepresentative: this.attr(null),
            merchantCategoryId: this.attr(null),
            merchantCategoryCode: this.attr(null),
            merchantCategoryName: this.string(''),
            totalMonthlyValueOfDeposits: this.attr(null),
            maxNumberOfDepositsPerMonth: this.attr(null),
            maxSingleDeposit: this.attr(0, (value) => Math.floor(value) || 0),
            address: this.morphOne(InvitationAddress, 'modelId', 'modelType'),
            operatingAddress: this.morphOne(InvitationOperatingAddress, 'modelId', 'modelType'),
            labCbd: this.morphOne(InvitationLabCbd, 'modelId', 'modelType'),
            registrationSystem: this.number(RegistrationSystem.Default),
            new: this.boolean(true),
            modelId: this.attr(null),
            modelType: this.attr(null),
            providerIdentifiers: this.attr(null),
            providerIdentifierSelected: this.boolean(false),
            businessType: this.attr(null)
        };
    }

    get merchantId() {
        return this.id;
    }
}
