import { Model } from '@vuex-orm/core';

import {
    Address,
    AddressOptions,
    UserAuth,
    Merchant
} from '@/store/models';
import { RegistrationSystem } from '@/constants/registration-system';

export class Client extends Model {
    static entity = 'client';

    static fields() {
        return {
            id: this.attr(null),
            email: this.attr(null),
            firstName: this.attr(null),
            lastName: this.attr(null),
            fatherName: this.attr(null),
            motherName: this.attr(null),
            birthDate: this.attr(null),
            birthCountryCode: this.attr(null),
            birthCity: this.attr(null),
            countryCode: this.attr(null),
            mobileNumber: this.attr(null),
            mobilePrefix: this.number(null),
            additionalMobiles: this.attr(null),
            termsAccepted: this.boolean(false),
            usCitizen: this.attr(null),
            usTaxIdentity: this.attr(null), // SSN / ITIN
            nationalityCountryCodes: this.attr(null),
            taxLiabilities: this.attr(null), // tax liabilities interface
            transactionalProfile: this.attr(null), // transactional profile interface
            sourceOfFundsCategories: this.attr(null),
            occupation: this.attr(null),
            accountPurpose: this.attr(null),
            new: this.boolean(false),
            consentGranted: this.boolean(false),
            registrationSystem: this.number(RegistrationSystem.Default),
            address: this.morphOne(Address, 'modelId', 'modelType'),
            additionalAddresses: this.attr(null),
            merchant: this.morphOne(Merchant, 'modelId', 'modelType')
        };
    }

    get mobile() {
        return `+${this.mobilePrefix}${this.mobileNumber}`;
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
}

export class ClientOptions {
    email: string;
    username: string;
    password: string;
    address: AddressOptions;
    lastName: string;
    firstName: string;
    oneTimePassword: string;
    birthCountryCode: string;
    birthDate: Date;
    culture: string;
    personId?: string;
    consentGranted: boolean;

    constructor(client: Client, userAuth?: UserAuth) {
        this.personId = client.id;
        this.firstName = client.firstName;
        this.username = client.mobileNumber;
        this.lastName = client.lastName;
        this.email = client.email;
        this.birthDate = client.birthDate;
        this.birthCountryCode = client.birthCountryCode;
        this.consentGranted = client.consentGranted ?? false;

        if (client.address) {
            this.address = new AddressOptions(client.address);
        }

        if (userAuth) {
            this.password = userAuth.password;
            this.oneTimePassword = userAuth.otp;
        }
    }
}
