import { ServiceResponse } from '@/utilities/services';
import { datadogRum } from '@datadog/browser-rum';
import { sanitizeClientData } from './sanitize';
import { LoggerOptions } from '../interfaces';

export function log(options: LoggerOptions) {
    const clientData = options.clientData || {};

    if (options.response) {
        clientData.response = getResponseLogDetails(options.response);
    }

    datadogRum.setGlobalContext({
        message: options.message,
        clientData: sanitizeClientData(options.clientData),
        correlationId: options.correlationId,
        eventId: options.eventId,
        useLogApi: options.useLogApi
    });
}

export function getResponseLogDetails(response: ServiceResponse) {
    return {
        eventId: response.eventId,
        correlationId: response.correlationId,
        status: response.status,
        data: response.data,
        url: response.request ? response.request.responseURL : null
    };
}
