import { Model } from '@vuex-orm/core';

import {
    KycProviderAddress
} from '@/store/models';
import { RegistrationSystem } from '@/constants/registration-system';

export class KycProviderClient extends Model {
    static entity = 'kycProviderClient';

    static fields() {
        return {
            id: this.attr(null),
            email: this.attr(null),
            firstName: this.attr(null),
            lastName: this.attr(null),
            fatherName: this.string(''),
            birthDate: this.attr(null),
            birthCountryCode: this.attr(null),
            birthCity: this.string(''),
            mobileNumber: this.attr(null),
            mobilePrefix: this.number(''),
            termsAccepted: this.boolean(false),
            usCitizen: this.boolean(false),
            usTaxIdentity: this.attr(null), // SSN / ITIN
            nationalityCountryCodes: this.attr(null),
            taxLiabilities: this.attr(null), // tax liabilities interface
            transactionalProfile: this.attr(null), // transactional profile interface
            sourceOfFundsCategories: this.attr(null),
            occupation: this.attr(null),
            accountPurpose: this.attr(null),
            provider: this.attr(null),
            consent: this.boolean(false), // user consent for getting data from kyc provider through kyc flow
            consentGranted: this.boolean(false),
            registrationSystem: this.number(RegistrationSystem.Default),
            new: this.boolean(false),
            address: this.morphOne(KycProviderAddress, 'modelId', 'modelType')
        };
    }

    static rules() {
        return {
            email: 'required|email',
            firstName: 'required|non_numeric_or_symbol|max:30',
            lastName: 'required|non_numeric_or_symbol|max:50',
            birthDate: 'required|under_age:18|over_age:100',
            birthCountryCode: 'required',
            mobileNumber: (data: any) => ({
                required: true,
                numeric: true,
                mobile: data.countryCode
            }),
            termsAccepted: 'required:true'
        };
    }

    get countryCode() {
        return this.address.countryCode;
    }

    get mobile() {
        if (!this.mobilePrefix) {
            return this.mobileNumber;
        }

        return `+${this.mobilePrefix}${this.mobileNumber}`;
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
}
