import Services, { ServicePromise } from '@/utilities/services';

import api from './config';

export default {
    requestPasswordToken(
        username: string,
        password: string,
        countryCode: string
    ): ServicePromise<any> {
        const services = new Services(api);
        const url = '/token/account';

        const data = {
            username,
            password,
            countryCode
        };

        return services.postData(url, data);
    },

    connectToMultipleAccounts(account: string, accessToken: string) : ServicePromise<any> {
        const services = new Services(api);
        const url = '/token/account:select';

        const data = {
            account,
            accessToken
        };

        return services.postData(url, data);
    }
};
