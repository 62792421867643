import bundle from '@/utilities/bundle';

export const ADD_PAYOUT_DETAILS_ROUTE = {
    path: '/add-payout-details/:id/:scope',
    name: 'add-payout-details',
    component: bundle.loadView('account-management/payout/bank-account-payout/bank-account-payout'),
    props: true
};

export const ADD_PAYOUT_DETAILS_ROUTES = [
    ADD_PAYOUT_DETAILS_ROUTE
];
