import bundle from '@/utilities/bundle';

export const ACCOUNT_MANAGEMENT_CLIENT_START_ROUTE = {
    path: '/',
    name: 'account-management-client-start',
    component: bundle.loadView('account-management/client/start/start'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_PERSONAL_DETAILS_ROUTE = {
    path: '/personal-details',
    name: 'account-management-client-personal-details',
    component: bundle.loadView('account-management/client/personal-details/personal-details'),
    props: true,
    meta: {
        title: 'headline.personalInfo'
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_BIRTH_DETAILS_ROUTE = {
    path: '/birth-details',
    name: 'account-management-client-birth-details',
    component: bundle.loadView('account-management/client/birth-details/birth-details'),
    props: true,
    meta: {
        title: 'headline.personalInfo'
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_PERSONAL_ADDRESS_ROUTE = {
    path: '/personal-address',
    name: 'account-management-client-personal-address',
    component: bundle.loadView('account-management/client/address/address'),
    props: true,
    meta: {
        title: 'headline.addressDetails'
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_US_CITIZENSHIP_ROUTE = {
    path: '/us-citizenship',
    name: 'account-management-client-us-citizenship',
    component: bundle.loadView('account-management/client/us-citizenship/us-citizenship'),
    props: true,
    meta: {
        title: 'headline.usCitizenship'
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_TAX_INFORMATION_ROUTE = {
    path: '/tax-information',
    name: 'account-management-client-tax-information',
    component: bundle.loadView('account-management/client/tax-information/tax-information'),
    props: true,
    meta: {
        title: 'headline.taxInformation'
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_FINANCIAL_PROFILE_ROUTE = {
    path: '/financial-profile',
    name: 'account-management-client-financial-profile',
    component: bundle.loadView('account-management/client/financial-profile/financial-profile'),
    props: true,
    meta: {
        title: 'headline.financialProfile'
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_SOURCE_OF_FUNDS_ROUTE = {
    path: '/source-of-funds',
    name: 'account-management-client-source-of-funds',
    component: bundle.loadView('account-management/client/source-of-funds/source-of-funds'),
    props: true,
    meta: {
        title: 'headline.sourceOfFunds'
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_OCCUPATION_ROUTE = {
    path: '/occupation',
    name: 'account-management-client-occupation',
    component: bundle.loadView('account-management/client/occupation/occupation'),
    props: true,
    meta: {
        title: 'headline.occupationCategory'
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_CONSENT_ROUTE = {
    path: '/consent',
    name: 'account-management-client-consent',
    component: bundle.loadView('account-management/client/consent/consent'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_PROCESS_ROUTE = {
    path: '/process',
    name: 'account-management-client-process',
    component: bundle.loadView('account-management/client/process/process'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_SUCCESS_ROUTE = {
    path: '/success',
    name: 'account-management-client-success',
    component: bundle.loadView('account-management/client/success/success'),
    props: true,
    meta: {
        title: ''
    }
};

export const ACCOUNT_MANAGEMENT_CLIENT_ROUTES = {
    path: '/account/personal/:id/:scope',
    children: [
        ACCOUNT_MANAGEMENT_CLIENT_START_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_PERSONAL_DETAILS_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_BIRTH_DETAILS_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_PERSONAL_ADDRESS_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_US_CITIZENSHIP_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_TAX_INFORMATION_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_FINANCIAL_PROFILE_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_SOURCE_OF_FUNDS_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_OCCUPATION_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_CONSENT_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_PROCESS_ROUTE,
        ACCOUNT_MANAGEMENT_CLIENT_SUCCESS_ROUTE
    ]
};
