import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';

import { State } from '@/store/database';

const StateOptions = {
    vuex: 'vuex-rep'
};

const persistStateOptions = {
    key: StateOptions.vuex,
    storage: {
        getItem: (key) => Vue.$sessionStorage.getItem(key),
        setItem: (key, value) => Vue.$sessionStorage.setItem(key, value),
        removeItem: (key) => Vue.$sessionStorage.removeItem(key)
    },
    reducer: (state: State) => ({
        entities: {
            rep: state.entities.rep
        }
    })
};

export function createPersist() {
    return {
        plugin: createPersistedState(persistStateOptions)
    };
}

export function removePersist() {
    Vue.$sessionStorage.removeItem('vuex-rep');
}
