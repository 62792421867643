import { COUNTRY_CODES } from '@/constants/country-codes';
import { COMPANY_TYPES } from '@/constants/company-types';

export function nonUk(value, { countryCode, companyType }) {
    try {
        if (companyType === COMPANY_TYPES.IndividualCasualUse) {
            return (countryCode !== COUNTRY_CODES.GreatBritain);
        }
        return true;
    } catch (e) {
        return false;
    }
}
