import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

import { Auth, AuthenticationScheme } from './auth';
import { TokenService } from "@/core/authentication";

export class IdentityAuth implements Auth {
    credentials: string;
    userManager: UserManager;

    constructor(
        readonly scheme: AuthenticationScheme,
        readonly settings: UserManagerSettings,
        readonly fallbackCredentials?: string | null,
        readonly fallbackTokenService?: TokenService) {
        this.userManager = new UserManager(this.settings);
    }

    async init() {
        const user = await this.userManager.getUser();

        if (user && !user.expired) {
            this.credentials = user.access_token;
        } else if (this.fallbackCredentials) {
            this.credentials = this.fallbackCredentials;
        } else if (this.fallbackTokenService) {
            const response = await this.fallbackTokenService.getToken();

            if (response.success) {
                this.credentials = response.data.token || '';
            }
        }
    }

    isValid() {
        return this.scheme && this.credentials !== '';
    }
}

