import createPersistedState from 'vuex-persistedstate';
import { State } from '@/store/database';

import * as FingerPrint from '@/core/fingerprint';

const StateOptions = {
    vuex: 'vuex-registration',
    duration: {
        days: 15
    }
};

const persistStateOptions = {
    reducer: (state: State) => ({
        entities: {
            client: state.entities.client,
            labCbd: state.entities.labCbd,
            address: state.entities.address,
            operatingAddress: state.entities.operatingAddress,
            merchant: state.entities.merchant
        }
    })
};

export function createPersist() {
    const storage = new FingerPrint.Store.LocalStorage(
        StateOptions.duration,
        new FingerPrint.Utilities.CryptoTransform()
    );

    const Persist = new FingerPrint.Store.Persist(
        StateOptions.vuex,
        storage,
        persistStateOptions
    );

    return {
        plugin: createPersistedState(Persist.persistedStateOptions),
        persist: Persist
    };
}
