import bundle from '@/utilities/bundle';

export const process = {
    path: '/process',
    children: [
        {
            path: '/success',
            name: 'process-success',
            component: bundle.loadView('process/success/success'),
            props: true,
            meta: {
                title: ''
            }
        }, {
            path: '/fail',
            name: 'process-fail',
            component: bundle.loadView('process/fail/fail'),
            props: true,
            meta: {
                title: ''
            }
        }
    ]
};
