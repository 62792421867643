import { Model } from '@vuex-orm/core';

export class KybProviderReference extends Model {
    static entity = 'kybProviderReference';

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(''),
            registrationNumber: this.attr(''),
            registered: this.boolean(false)
        };
    }
}
