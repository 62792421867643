import Vue from 'vue';
import moment from 'moment';
import { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';

import PromiseHelper, { CacheOptions, CacheData, VIVA_CORRELATION_ID } from './promise-helper';
import { Auth } from '@/core/authentication';

export interface ServiceResponse<T = any> extends AxiosResponse {
    success: boolean;
    eventId?: string;
    correlationId?: string;
    data: any;
}

export interface ServicePromise<T = any> extends Promise<ServiceResponse<T>> {}

export default class Services extends PromiseHelper {
    storage: Storage;
    correlationId?: string;

    constructor(
        readonly api: AxiosInstance,
        readonly auth: Auth | null = null,
        readonly options: CacheOptions = { name: '', cache: false, storage: Vue.$localStorage }
    ) {
        super();

        if (!options.duration) {
            options.duration = {
                days: 1
            };
        }

        this.storage = options.storage || Vue.$localStorage;
    }

    isExpired(creation: string) {
        const ttl = moment(creation).add(this.options.duration);

        return ttl.isBefore(moment());
    }

    fetchCacheData(): CacheData | null {
        if (!this.options.cache || this.options.forceUpdate) {
            return null;
        }

        const storageData = JSON.parse(this.storage.getItem(this.options.name) || '{}');

        if (!storageData.data || this.isExpired(storageData.creation)) {
            return null;
        }

        return storageData;
    }

    insertCacheData(data) {
        if (!this.options.cache) {
            return;
        }

        const item : CacheData = {
            data,
            creation: new Date()
        };

        this.storage.setItem(this.options.name, JSON.stringify(item));
    }

    async applyAuth(config?: AxiosRequestConfig) {
        if (!this.auth) {
            return config;
        }

        config = config || {};
        config.headers = config.headers || {};

        await this.auth.init();

        if (this.auth.isValid()) {
            config.headers.Authorization = `${this.auth.scheme} ${this.auth.credentials}`;
        }

        return config;
    }

    applyCorrelation(config?: AxiosRequestConfig) {
        if (!this.correlationId) {
            return config;
        }

        if (!config) {
            config = config || {};
        }

        config.headers = config.headers || {};

        config.headers[VIVA_CORRELATION_ID] = this.correlationId;

        return config;
    }

    getData(url: string, config?: AxiosRequestConfig): ServicePromise<ServiceResponse<any>> {
        const cacheData = this.fetchCacheData();

        if (cacheData) {
            return this.createPromise(cacheData);
        }

        return (async () => {
            let response;

            try {
                config = await this.applyAuth(
                    this.applyCorrelation(config)
                );

                response = await this.api.get(url, config) as ServiceResponse;

                response.success = this.isResponseSuccess(response.status);

                this.insertCacheData(response.data);

                return this.successPromise(response);
            } catch (error) {
                return this.failPromise(error.response);
            }
        })();
    }

    postData(url: string, data?: any, config?: AxiosRequestConfig): ServicePromise<ServiceResponse<any>> {
        const cacheData = this.fetchCacheData();

        if (cacheData) {
            return this.createPromise(cacheData);
        }

        return (async () => {
            let response;

            try {
                config = await this.applyAuth(
                    this.applyCorrelation(config)
                );

                response = await this.api.post(url, data, config) as ServiceResponse;

                response.success = this.isResponseSuccess(response.status);

                this.insertCacheData(response.data);

                return this.successPromise(response);
            } catch (error) {
                return this.failPromise(error.response);
            }
        })();
    }

    putData(url: string, data?: any, config?: AxiosRequestConfig): ServicePromise<ServiceResponse<any>> {
        return (async () => {
            let response;

            try {
                config = await this.applyAuth(
                    this.applyCorrelation(config)
                );

                response = await this.api.put(url, data, config) as ServiceResponse;

                response.success = this.isResponseSuccess(response.status);

                this.insertCacheData(response.data);

                return this.successPromise(response);
            } catch (error) {
                return this.failPromise(error.response);
            }
        })();
    }

    patchData(url: string, data?: any, config?: AxiosRequestConfig): ServicePromise<ServiceResponse<any>> {
        return (async () => {
            let response;

            try {
                config = await this.applyAuth(
                    this.applyCorrelation(config)
                );

                response = await this.api.patch(url, data, config) as ServiceResponse;

                response.success = this.isResponseSuccess(response.status);

                this.insertCacheData(response.data);

                return this.successPromise(response);
            } catch (error) {
                return this.failPromise(error.response);
            }
        })();
    }

    deleteData(url: string, config?: AxiosRequestConfig): ServicePromise<ServiceResponse<any>> {
        return (async () => {
            let response;

            try {
                config = await this.applyAuth(
                    this.applyCorrelation(config)
                );

                response = await this.api.delete(url, config) as ServiceResponse;

                response.success = this.isResponseSuccess(response.status);

                return this.successPromise(response);
            } catch (error) {
                return this.failPromise(error.response);
            }
        })();
    }
}
