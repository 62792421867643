import { convertHexToRGBA } from '@/core/helpers/colors';

export function applyThemeColor(hexCode: string) {
    if (!hexCode) {
        return;
    }

    const baseColor = convertHexToRGBA(hexCode);
    const root = document.documentElement;
    const { red, green, blue } = baseColor;
    const o = Math.round(((red * 299) + (green * 587) + (blue * 114)) / 1000);
    const hex = hexCode.replace('#', '');

    root.style.setProperty('--v-primary-base', `#${hex}`);
    root.style.setProperty('--v-secondary-base', `#${hex}`);
    root.style.setProperty('--v-primary-text-base', `#${hex}`);
    root.style.setProperty('--v-secondary-text-base', `#${hex}`);
    root.style.setProperty('--v-anchor-base', `#${hex}`);

    if (o > 141) {
        root.style.setProperty('--color-text-contrast', 'rgba(0, 0, 0, 0.87)');
    } else {
        root.style.setProperty('--color-text-contrast', 'white');
    }
}
