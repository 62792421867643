export const COUNTRY_CODES = {
    AmericanSamoa: 'AS',
    Anguilla: 'AI',
    Austria: 'AT',
    Bahamas: 'BS',
    Belgium: 'BE',
    Bulgarian: 'BG',
    Croatia: 'HR',
    Cyprus: 'CY',
    CzechRepublic: 'CZ',
    Denmark: 'DK',
    Estonia: 'EE',
    Finland: 'FI',
    Fiji: 'FJ',
    France: 'FR',
    Germany: 'DE',
    Guadeloupe: 'GP',
    Gibraltar: 'GI',
    GreatBritain: 'GB',
    Greek: 'GR',
    Guam: 'GU',
    Hungary: 'HU',
    Iceland: 'IS',
    Ireland: 'IE',
    Italy: 'IT',
    Latvia: 'LV',
    Liechtenstein: 'LI',
    Lithuania: 'LT',
    Luxembourg: 'LU',
    Malta: 'MT',
    Martinique: 'MQ',
    Monaco: 'MC',
    Netherlands: 'NL',
    Norway: 'NO',
    Palau: 'PW',
    Panama: 'PA',
    Poland: 'PL',
    Portugal: 'PT',
    Romania: 'RO',
    Reunion: 'RE',
    Russia: 'RU',
    Samoa: 'WS',
    SanMarino: 'SM',
    Slovakia: 'SK',
    Slovenia: 'SI',
    Spain: 'ES',
    Sweden: 'SE',
    Switzerland: 'CH',
    TrinidadTobago: 'TT',
    TurksAndCaicosIslands: 'TC',
    Ukraine: 'UA',
    USVirginIslands: 'VI',
    Vanuatu: 'VU'
};
