import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import jwt from 'jsonwebtoken';

import PromiseHelper from '@/utilities/promise-helper';
import { PROMPT_TYPES } from '@/core/authentication/constants';

@Component
export default class Identity extends Vue {
    userManager: UserManager;

    initAuth(lang?: string, countryCode?: string) {
        if (lang) {
            this.setQueryParams({
                ui_locales: lang
            });
        }

        if (countryCode) {
            this.setQueryParams({
                country_code: countryCode
            });
        }

        this.userManager = new UserManager(config.identityServerSettings);
    }

    addExpirationEvent() {
        this.userManager.events.addAccessTokenExpired(this.signinRedirect);
    }

    async authorizeUser(options?: any) {
        if (options && options.prompt === PROMPT_TYPES.login ) {
            await this.signinRedirect(options);

            return false;
        }

        const loginUserResponse = await this.isUserLoggedInPromise();

        if (!loginUserResponse.success) {
            await this.signinRedirect(options);

            return false;
        } else if (this.isUserExpired()) {
            //await this.userManager.removeUser();
            //await this.signinRedirect();
        }

        return true;
    }

    async getUser() {
        return await this.userManager.getUser();
    }

    getStoreKey() {
        return `user:${config.identityServerSettings.authority}:${config.identityServerSettings.client_id}`;
    }

    async storeUser(accessToken: string, tokenType: string) {
        let decoded = jwt.decode(accessToken, {complete: true});

        if (decoded && decoded.payload) {
            const options = {
                access_token: accessToken,
                token_type: tokenType,
                scope: decoded.payload.scope.join(' '),
                profile: {
                    amr: decoded.payload.amr,
                    auth_time: decoded.payload.auth_time,
                    idp: decoded.payload.idp,
                    sub: decoded.payload.sub
                },
                expires_at: decoded.payload.exp
            };

            const store = new WebStorageStateStore({
                store: window.sessionStorage
            });

            await store.set(this.getStoreKey(), JSON.stringify(options));
        }
    }

    async signoutRedirect(redirectUri?: string, ignoreResourceOwner?: boolean) {
        const user = await this.getUser();

        if (!user) {
            return;
        }

        if (await this.hasResourceOwner() && !ignoreResourceOwner) {
            await this.userManager.removeUser();

            window.location.href = redirectUri || config.identityServerSettings.post_logout_redirect_uri;

            return;
        }

        if (typeof redirectUri === 'string' && redirectUri !== '') {
            await this.userManager.signoutRedirect({
                post_logout_redirect_uri: redirectUri
            });

            return;
        }

        await this.userManager.signoutRedirect();
    }

    async hasResourceOwner() {
        let user = await this.getUser();

        return user && !user.id_token;
    }

    async isUserExpired() {
        let user = await this.getUser();

        return user && user.expired;
    }

    async isUserLoggedIn() {
        let user = await this.getUser();

        return user !== null;
    }

    async isUserLoggedInPromise() {
        return await this.isUserLoggedIn()
            ? new PromiseHelper().successPromise()
            : new PromiseHelper().failPromise();
    }

    async redirectLoggedInUser() {
        try {
            let user = await this.userManager.signinRedirectCallback();

            return user
                ? new PromiseHelper().successPromise()
                : new PromiseHelper().failPromise();
        } catch (e) {
            return new PromiseHelper().failPromise();
        }
    }

    async redirectLoggedOutUser() {
        try {
            let user = await this.userManager.signoutRedirectCallback();

            return user
                ? new PromiseHelper().successPromise()
                : new PromiseHelper().failPromise();
        } catch (e) {
            return new PromiseHelper().failPromise();
        }
    }
    
    async signinRedirect(options?: any) {
        await this.userManager.signinRedirect(options);
    }

    async signinSilent(options?: any) {
        await this.userManager.signinSilent(options);
    }

    async getUserProfile() {
        let user = await this.getUser();

        return user ? user.profile : null;
    }


    async getUserRoles() {
        let user = await this.getUser();

        if (!user) {
            return null;
        }

        let decoded = jwt.decode(user.access_token, {complete: true});

        return (decoded && decoded.payload)
            ? decoded.payload.role
            : null;
    }

    async getUserId() {
        const profile = await this.getUserProfile();

        return profile ? profile.sub : null;
    }

    async getUserAccessToken() {
        let user = await this.getUser();

        return user ? user.access_token : '';
    }

    setQueryParams(options) {
        if (!config.identityServerSettings.extraQueryParams) {
            config.identityServerSettings.extraQueryParams = options;
        } else {
            Object.assign(config.identityServerSettings.extraQueryParams, options);
        }

        if (!this.userManager) {
            return;
        }

        Object.assign(this.userManager.settings.extraQueryParams, options);
    }
}
