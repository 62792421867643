import createPersistedState from 'vuex-persistedstate';
import { State } from '@/store/database';

import * as FingerPrint from '@/core/fingerprint';

const StateOptions = {
    vuex: 'vuex-kyc-provider'
};

const persistStateOptions = {
    reducer: (state: State) => ({
        entities: {
            kycProviderClient: state.entities.kycProviderClient,
            kycProviderAddress: state.entities.kycProviderAddress
        }
    })
};

export function createPersist() {
    const storage = new FingerPrint.Store.SessionStorage(
        new FingerPrint.Utilities.CryptoTransform()
    );

    const Persist = new FingerPrint.Store.Persist(
        StateOptions.vuex,
        storage,
        persistStateOptions
    );

    return {
        plugin: createPersistedState(Persist.persistedStateOptions),
        persist: Persist
    };
}
