import { withPrefix } from '@/utilities/route';

import { ACCOUNT_MANAGEMENT_BASE_ROUTES } from './base';
import { ACCOUNT_MANAGEMENT_CLIENT_ROUTES } from './client';
import { ACCOUNT_MANAGEMENT_BUSINESS_ROUTES } from './business';

export const ACCOUNT_MANAGEMENT_ROUTES = [
    ...withPrefix(ACCOUNT_MANAGEMENT_BASE_ROUTES.path, ACCOUNT_MANAGEMENT_BASE_ROUTES.children),
    ...withPrefix(ACCOUNT_MANAGEMENT_CLIENT_ROUTES.path, ACCOUNT_MANAGEMENT_CLIENT_ROUTES.children),
    ...withPrefix(ACCOUNT_MANAGEMENT_BUSINESS_ROUTES.path, ACCOUNT_MANAGEMENT_BUSINESS_ROUTES.children)
];
