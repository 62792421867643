import { VIVA_COM_LEAD_FORM_REDIRECT_ROUTE } from './redirect';

export const KYC_PROVIDER_START_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '',
    name: 'kyc-provider'
};

export const KYC_PROVIDER_CONSENT_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/consent',
    name: 'kyc-provider-consent'
};

export const KYC_PROVIDER_PERSONAL_DETAILS_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/personal-details',
    name: 'kyc-provider-personal-details'
};

export const KYC_PROVIDER_US_CITIZENSHIP_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/us-citizenship',
    name: 'kyc-provider-us-citizenship'
};

export const KYC_PROVIDER_TAX_INFORMATION_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/tax-information',
    name: 'kyc-provider-tax-information'
};

export const KYC_PROVIDER_FINANCIAL_PROFILE_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/financial-profile',
    name: 'kyc-provider-financial-profile'
};

export const KYC_PROVIDER_SOURCE_OF_FUNDS_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/source-of-funds',
    name: 'kyc-provider-source-of-funds'
};

export const KYC_PROVIDER_OCCUPATION_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/occupation',
    name: 'kyc-provider-occupation'
};

export const KYC_PROVIDER_PERSONAL_ADDRESS_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/personal-address',
    name: 'kyc-provider-address'
};

export const KYC_PROVIDER_PASSWORD_ROUTE = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/password',
    name: 'kyc-provider-password'
};

export const KYC_PROVIDER_ROUTES = {
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/kyc/:provider/:referenceId',
    children: [
        KYC_PROVIDER_START_ROUTE,
        KYC_PROVIDER_CONSENT_ROUTE,
        KYC_PROVIDER_PERSONAL_DETAILS_ROUTE,
        KYC_PROVIDER_US_CITIZENSHIP_ROUTE,
        KYC_PROVIDER_TAX_INFORMATION_ROUTE,
        KYC_PROVIDER_FINANCIAL_PROFILE_ROUTE,
        KYC_PROVIDER_SOURCE_OF_FUNDS_ROUTE,
        KYC_PROVIDER_OCCUPATION_ROUTE,
        KYC_PROVIDER_PERSONAL_ADDRESS_ROUTE,
        KYC_PROVIDER_PASSWORD_ROUTE
    ]
};

export const kycProviderReview = [{
    ...VIVA_COM_LEAD_FORM_REDIRECT_ROUTE,
    path: '/kyc/:provider/review',
    name: 'kyc-provider-review'
}];
