import bundle from '@/utilities/bundle';
import { INVITATION_ROOT_PATH } from '@/routes/invitation/base';
import { join } from '@/utilities/url';

export const INVITATION_BUSINESS_START_ROUTE = {
    path: '/',
    name: 'invite-business',
    component: bundle.loadView('invitation/business/start/start'),
    props: true
};

export const INVITATION_BUSINESS_ACCOUNT_TYPE_ROUTE = {
    path: '/account-type',
    name: 'invite-account-type',
    component: bundle.loadView('invitation/business/account-type/account-type'),
    props: true
};

export const INVITATION_BUSINESS_MANAGE_ROUTE = {
    path: '/manage',
    name: 'invite-business-manage',
    component: bundle.loadView('invitation/business/manage/manage'),
    props: true
};

export const INVITATION_BUSINESS_SEARCH_ROUTE = {
    path: '/search',
    name: 'invite-search',
    component: bundle.loadView('invitation/business/search/search'),
    props: true
};

export const INVITATION_BUSINESS_SELECT_COMPANY_ROUTE = {
    path: '/select-company',
    name: 'invite-select-company',
    component: bundle.loadView('invitation/business/select-company/select-company'),
    props: true
};

export const INVITATION_BUSINESS_DETAILS_ROUTE = {
    path: '/account',
    name: 'invite-business-account',
    component: bundle.loadView('invitation/business/account/account'),
    props: true
};

export const INVITATION_BUSINESS_PAYOUT_DETAILS_ROUTE = {
    path: '/payout',
    name: 'invite-business-payout',
    component: bundle.loadView('invitation/business/payout/payout'),
    props: true
};

export const INVITATION_BUSINESS_PAYOUT_BANK_ROUTE = {
    path: '/payout/bank-account-payout',
    name: 'invite-business-payout-bank',
    component: bundle.loadView('invitation/business/payout/components/bank-account-payout/bank-account-payout'),
    props: true
};

export const INVITATION_BUSINESS_PAYOUT_VIVA_COM_ROUTE = {
    path: '/payout/viva-com-payout',
    name: 'invite-business-payout-viva-com',
    component: bundle.loadView('invitation/business/payout/components/viva-com-payout/viva-com-payout'),
    props: true
};

export const INVITATION_BUSINESS_DELIVERY_ROUTE = {
    path: '/delivery',
    name: 'invite-business-delivery',
    component: bundle.loadView('invitation/business/delivery/delivery'),
    props: true
};

export const INVITATION_BUSINESS_CONFIRM_ROUTE = {
    path: '/confirm',
    alias: '/confirm/:accountId',
    name: 'invite-business-confirm',
    component: bundle.loadView('invitation/business/confirm/confirm'),
    props: true
};

export const INVITATION_BUSINESS_PROCESS_ROUTE = {
    path: '/process',
    name: 'invite-business-process',
    component: bundle.loadView('invitation/business/process/process'),
    props: true
};

export const INVITATION_BUSINESS_SUCCESS_ROUTE = {
    path: '/success',
    name: 'invite-business-success',
    component: bundle.loadView('invitation/business/success/success'),
    props: true
};

export const INVITATION_UNSUPPORTED_IBAN = {
    path: '/unsupported/iban',
    name: 'invite-unsupported-iban',
    component: bundle.loadView('invitation/business/unsupported/unsupported-iban/unsupported-iban'),
    props: true
};

export const INVITATION_UNSUPPORTED_PERSONAL = {
    path: '/unsupported/personal',
    name: 'invite-unsupported-personal',
    component: bundle.loadView('invitation/business/unsupported/unsupported-personal/unsupported-personal'),
    props: true
};

export const INVITATION_BUSINESS_ROUTES = {
    path: join(INVITATION_ROOT_PATH, '/business'),
    children: [
        INVITATION_UNSUPPORTED_IBAN,
        INVITATION_UNSUPPORTED_PERSONAL,
        INVITATION_BUSINESS_ACCOUNT_TYPE_ROUTE,
        INVITATION_BUSINESS_START_ROUTE,
        INVITATION_BUSINESS_SEARCH_ROUTE,
        INVITATION_BUSINESS_SELECT_COMPANY_ROUTE,
        INVITATION_BUSINESS_DETAILS_ROUTE,
        INVITATION_BUSINESS_PROCESS_ROUTE,
        INVITATION_BUSINESS_SUCCESS_ROUTE
    ]
};
