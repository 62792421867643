import api from './config';
import Services, { ServicePromise } from '@/utilities/services';
import { TokenService } from '@/core/authentication';

export class AuthTokenService implements TokenService {
    getToken(): ServicePromise<any> {
        const services = new Services(api);
        const url = '/token';

        return services.getData(url);
    }
}
