import moment from 'moment';

export function isDateFormat(value: string, format: string) {
    try {
        const formattedDate = moment(value, format || 'YYYY-MM-DD');

        return formattedDate.isValid();
    } catch (e) {
        return false;
    }
}
